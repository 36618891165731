import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBrain } from 'react-icons/fa';
import { useAuth0 } from '@auth0/auth0-react';
import { Context } from 'context/context';
import { IContext } from 'interfaces/interfaces';
import {
    beginnerLevelTheory,
    beginnerSet1,
    studyModeMeaningsRadicals,
    studyModeOnyomiKunyomi,
    testModeKanji,
    testModeMeanings,
    testModeMeaningsKanji,
    testModeOnyomi,
    testModeKunyomi,
    testModeOnyomiKunyomi,
    onyomiKunyomi,
} from 'assets/images';

export default function Theory(): JSX.Element {
    const { setFlashcardsPage } = useContext(Context) as IContext;

    useEffect(() => {
        setFlashcardsPage(false);
    }, []);

    const { loginWithRedirect } = useAuth0();

    return (
        <div className="page-container">
            <div className="page-content">
                <h6>THEORY</h6>
                <FaBrain size="100px" className="siteinfo-red-icon"/>
                <p>
                    The best way to learn thousands of Japanese characters called kanji <span className="theory-japanese-text">漢字 </span>
                    is two-fold: first by learning simple kanji followed by complex kanji built from the simpler ones,
                    and then by using short mnemonic devices.
                </p>
                <br/>
                <p>
                    The Kanji Remastered flashcard system breaks down the 3,000 most commonly used kanji into three levels 
                    — Beginner, Intermediate, and Advanced — of 10 sets of 100 kanji each, for a total of 1,000 kanji per level. 
                    It assumes that you already know hiragana and katakana.
                </p>
                <br/>
                <p>
                    Each kanji flashcard has a mnemonic device that improves on the Heisig method, 
                    maximizing it with concise stories that teach the important concepts of kanji all at once: meaning, structure, and pronunciation. 
                </p>
                <br/>
                <p>
                    The Beginner level is roughly equivalent to the Japanese-Language Proficiency Test N5 and N4 level kanji plus radicals. 
                    This is enough to read over 50% of Japanese text. 
                    The Intermediate level corresponds to JLPT N3, N2 and most of N1 kanji. 
                    This is enough to read over 95% of Japanese text.
                </p>
                <br/>
                <p>
                    And finally, the Advanced level covers the rest of JLPT N1 and most of the Kanji Kentei Pre-level 1 exam kanji. 
                    It is enough to read well over 99.9% of Japanese text. If you make it here, you are (almost) a kanji master!
                </p>
                <br/>
                <img className="theory-img beginner" src={beginnerLevelTheory} alt="beginner level screenshot"/>
                <br/><br/>

                <h5>Why 2,500 Kanji?</h5>
                <br/>
                <p>
                    The list of most commonly used kanji known as the Jouyou Kanji issued by the Japanese Ministry of Education includes about 2,000 characters. 
                    However, there are well over 1,000 other kanji that appear often enough in names and literature such that most Japanese people know or at least can recognize them.
                </p>
                <br/>
                <p>
                    The Beginner and Intermediate levels cover the most frequently used kanji from the Jouyou Kanji list which is almost all of them. 
                    The Advanced level covers the remaining Joyou Kanji and many of the Jinmeiyou Kanji used for names and hundreds of other useful but less common kanji.
                </p>
                <br/>
                <p>
                    To truly consider oneself to have <em>basic</em> literate in Japanese, these 2,500 kanji must be known. 
                    These 2,500 kanji account for practically all kanji used in everyday life outside of literature, newspapers and technical documents.
                </p>
                <p>
                    Also keep in mind that Kanji Remastered includes about 220 radicals and there are 2,136 Joyo Kanji. 
                    This means that, apart from radicals, this list includes approximately an extra 150 useful kanji only.
                </p>
                <br/><br/>

                <h5>Mnemonic Devices</h5>
                <br/>
                <p>
                    Mnemonic devices are memory aids in the form of simple stories. All the mnemonic devices follow a pattern. 
                    The first sentence will usually contain only the meaning followed by the radicals. 
                    Radicals are incoporated into the mnemonic in the order they are written in the kanji.
                </p>
                <br/>
                <p>
                    The second sentence will first have the onyomi which are the Chinese readings, followed by the kunyomi which are the Japanese readings. 
                    If there is only one common reading, the mnemonic will usually be just one sentence for brevity's sake.
                </p>
                <br/>
                <p>
                    It is important that the mnemonic devices follow this structure of 
                    <strong>
                        &nbsp; Sentence 1
                        <span className="m-highlight"> Meaning</span>
                        —
                        <span className="r-highlight">Radicals</span>.
                        Sentence 2 
                        <span className="o-highlight"> Onyomi</span>
                        —
                        <span className="k-highlight">Kunyomi</span>
                    </strong>
                    .
                </p>
                <br/>
                <p>
                    This allows your memory to naturally expect things in the same place and thus makes remembering the mnemonics much easier.
                    For example, here is the mnemonic for 'see', 見
                    which is composed of the two radicals 'eye' and 'legs' and with the readings of 'KAN' and 'miru'.
                </p>
                <br/>
                <p className="theory-mnemonic">
                    I <span className="m-highlight">see</span> with my <span className="r-highlight">eye</span> my <span className="r-highlight">legs</span>. 
                    I also see <span className="o-highlight">Ken</span> in the <span className="k-highlight">mirror</span>.
                </p>
                <br/>
                <br/>
                <p>
                    Creating your own mnemonic devices and notes further boosts the efficiency of this system. <span className="theory-login-link" onClick={() => loginWithRedirect()}>
                    By creating a free account with Kanji Remastered
                    </span>, you can actually save your own notes directly to a flashcard that only you can see. 
                    All kanji pros make use of personalized mnemonics. <strong>I can't recommend this enough.</strong>
                </p>
                <br/><br/>


                <h5>Kanji and Meanings</h5>
                <br/>
                <p>
                    Kanji have multipe shades of meaning, often overlapping with each other which adds to the complexity of learning them. 
                    Kanji Remastered gives each kanji one unique meaning so that you can unambigualy associate each kanji with it. 
                    A handful of very common kanji are given two or three unique meanings, however.
                </p>
                <br/><br/>

                <h5>Radicals, Simple Kanji and Complex Kanji</h5>
                <br/>
                <p>
                    Kanji must be learned starting with radicals and very simple kanji upon which the remaining thousands of other kanji are built.
                    Kanji should not be learned soley based on which kanji are more common which is exactly the method traditonal textbooks use.
                    Anyone who has learned to read Japanese as a second language learner knows this. 
                </p>
                <br/>
                <p>
                    Kanji Remastered breaks down every single kanji into its most basic components and assigns a name and mnemonic to each component. 
                    Even in the Beginner level you will find some relatively rare kanji because they are a component of other very common yet complex kanji. 
                </p>
                <br/>
                <p>
                    Another excellent feature of Kanji Remastered is that confusingly similar looking kanji are presented one right after 
                    the other to highlight their similarity. This forces you, the learner, to clearly learn the subtle differences between them 
                    so that you don't confuse them.
                </p>
                <br/><br/>

                <h5>Pronunciation: Onyomi and Kunyomi Readings</h5>
                <br/>
                <img className="theory-img readings" src={onyomiKunyomi} alt="onyomi and kunyomi readings"/>
                <p>
                    Onyomi <span className="theory-japanese-text">音読み </span>
                    are the Chinese derived pronunciations of kanji. Most kanji have one onyomi but many have more and a few have none. 
                    Kunyomi <span className="theory-japanese-text">訓読み </span>
                    are the native Japanese pronunciations. Most kanji have multiple kunyomis and they sound nothing like the onyomis. 
                    Quite a few kanji have no kunyomis at all.
                </p>
                <br/>
                <p>
                    For kunyomi, part of it is usually written in hiragana so it is easier to guess the pronunciation. 
                    The part written in hiragana, known as okurigana, is indicated in the flashcards with a preceding period like in hito.tsu, 'one' 一.
                </p>
                <br/>
                <p>
                    Kanji Remastered purposefully teaches only the one or two most common onyomi and kunyomi to not bog down the learner at the beginning stages with advanced readings. 
                    Sometimes a reading will be included in the flashcard but not in the mnemonic device because it is only somewhat common and perhaps not worth memorizing on the first pass.
                    Even less common readings are best picked up from reading actual Japanese text and thus not included.
                </p>
                <br/><br/>


                <h5>How to Use the Kanji Remastered Flashcards</h5>
                <br/>
                <p>
                    It should take you approximately one year and six months to go through the entire flashcard system. You should start with the Beginner kanji flashcards. 
                    The first five weeks you should go over all of the five sets focusing only on the meanings and radicals. You should dedicate one week for each set of 100 kanji in the following way:
                </p>
                <br/>
                <img className="theory-img beginner" src={beginnerSet1} alt="beginner level screenshot"/>
                <br/>
                <br/>

                <p className="day">Pro Tip</p>
                <p>In STUDY MODE try to remember the mnemonic device. In TEST MODE only try to recall the correct answer.</p>
                <br/>
                <br/>

                <p className="day">Day 1 Morning</p>
                <p>STUDY MODE, Meanings and Radicals only, Kanji side up</p>
                <br/>
                <p className="day">Day 1 Evening</p>
                <p>STUDY MODE, Meanings and Radicals only, Meaning side up</p>
                <img className="theory-img flashcard-options" src={studyModeMeaningsRadicals} alt="study mode screenshot"/>
                <br/>

                <p className="day">Day 2 Morning</p>
                <p>TEST MODE, Meanings</p>
                <br/>
                <p className="day">Day 2 Evening</p>
                <p>TEST MODE, Meanings</p>
                <img className="theory-img flashcard-options" src={testModeMeanings} alt="study mode screenshot"/>
                <br/>

                <p className="day">Day 3 Morning</p>
                <p>STUDY MODE, Meanings and Radicals only, Meaning side up</p>
                <br/>
                <p className="day">Day 3 Evening</p>
                <p>STUDY MODE, Meanings and Radicals only, Kanji side up</p>
                <img className="theory-img flashcard-options" src={studyModeMeaningsRadicals} alt="study mode screenshot"/>
                <br/>

                <p className="day">Day 4 Morning</p>
                <p>TEST MODE, Kanji</p>
                <br/>
                <p className="day">Day 4 Evening</p>
                <p>TEST MODE, Kanji</p>
                <img className="theory-img flashcard-options" src={testModeKanji} alt="study mode screenshot"/>
                <br/>

                <p className="day">Day 5 Morning</p>
                <p>STUDY MODE, Meanings and Radicals only, Kanji side up</p>
                <br/>
                <p className="day">Day 5 Evening</p>
                <p>STUDY MODE, Meanings and Radicals only, Meaning side up</p>
                <img className="theory-img flashcard-options" src={studyModeMeaningsRadicals} alt="study mode screenshot"/>
                <br/>

                <p className="day">Day 6 Morning</p>
                <p>TEST MODE, Meanings and Kanji</p>
                <br/>
                <p className="day">Day 6 Evening</p>
                <p>TEST MODE, Meanings and Kanji</p>
                <img className="theory-img flashcard-options" src={testModeMeaningsKanji} alt="study mode screenshot"/>
                <br/>

                <p className="day">Day 7</p>
                <p>Rest your memory</p>
                <br/>
                <br/>

                <p>After you've gone over the five sets over ten weeks start the process over but this time focusing on the onyomi and kunyomi readings.</p>
                <br/>
                <br/>

                <p className="day">Day 1 Morning</p>
                <p>STUDY MODE, Onyomi and Kunyomi only, Kanji side up</p>
                <br/>
                <p className="day">Day 1 Evening</p>
                <p>STUDY MODE, Onyomi and Kunyomi only, Meaning side up</p>
                <img className="theory-img flashcard-options" src={studyModeOnyomiKunyomi} alt="study mode screenshot"/>
                <br/>

                <p className="day">Day 2 Morning</p>
                <p>TEST MODE, Onyomi</p>
                <br/>
                <p className="day">Day 2 Evening</p>
                <p>TEST MODE, Onyomi</p>
                <img className="theory-img flashcard-options" src={testModeOnyomi} alt="study mode screenshot"/>
                <br/>

                <p className="day">Day 3 Morning</p>
                <p>STUDY MODE, Onyomi and Kunyomi only, Meaning side up</p>
                <br/>
                <p className="day">Day 3 Evening</p>
                <p>STUDY MODE, Onyomi and Kunyomi only, Kanji side up</p>
                <br/>
                <img className="theory-img flashcard-options" src={studyModeOnyomiKunyomi} alt="study mode screenshot"/>

                <p className="day">Day 4 Morning</p>
                <p>TEST MODE, Kunyomi</p>
                <br/>
                <p className="day">Day 4 Evening</p>
                <p>TEST MODE, Kunyomi</p>
                <img className="theory-img flashcard-options" src={testModeKunyomi} alt="study mode screenshot"/>
                <br/>

                <p className="day">Day 5 Morning</p>
                <p>STUDY MODE, Onyomi and Kunyomi only, Kanji side up</p>
                <br/>
                <p className="day">Day 5 Evening</p>
                <p>STUDY MODE, Onyomi and Kunyomi only, Meaning side up</p>
                <img className="theory-img flashcard-options" src={studyModeOnyomiKunyomi} alt="study mode screenshot"/>
                <br/>

                <p className="day">Day 6 Morning</p>
                <p>TEST MODE, Onyomi and Kunyomi</p>
                <br/>
                <p className="day">Day 6 Evening</p>
                <p>TEST MODE, Onyomi and Kunyomi</p>
                <img className="theory-img flashcard-options" src={testModeOnyomiKunyomi} alt="study mode screenshot"/>
                <br/>

                <p className="day">Day 7</p>
                <p>Rest your memory</p>
                <br/>

                <p>At this point it's been 10 weeks and you've gone over every set twice. Do the same for sets 6 through 10 for another 10 weeks. After this second ten week period take the last four weeks of the six month cycle to review every set again in TEST MODE, taking one day off a week. In this period you can focus on the kanji you find hardest to remember by building your own custom sets.</p>
                <br/>

                <p>After spending six months with the Beginner level sets, do the same thing for the Intermediate and Advanced level sets.</p>
                <br/>
                <br/>


                <h5>How Do I Keep Track of My Progress?</h5>
                <br/>
                <p>
                    The goal is <em>not</em> to remember every single kanji you have studied thus far. 
                    If you obsess over trying to remember every single kanji after studying it, you will get stuck and <em>never</em> progress.
                </p>
                <br/>
                <p>  
                    Do you think Japanese kids get a 100% on every kanji test in school? Not even close. 
                    They brain dump most of it and pick up the remainder as they go through life seeing Japanese all around them.
                    You are aiming for approximately a <strong>30% to 50% recognition rate</strong>. Woah?! that sounds so low! 
                    Actually it's not. Only a mad genius could remember for life every single kanji after studying them only a few times.
                </p>
                <br/>
                <p>
                    Kanji are learned through constant exposure in vivo, that is, by seeing them in action in real Japanese text. 
                    Fortunately, you are probably not just studying Kanji. You are likely taking classes or studing for some JLPT exam, 
                    reading manga, watching anime, writing haiku, flipping through Japanese magazines or books and all around consuming Japanese content.
                </p>
                <br/>
                <p>
                    You will be amazed that even though you will only remember about a third of the kanji studied after going through Kanji Remastered, 
                    you will also be able to recognize <em>all</em> the kanji that you studied even though you cannot quite remember their meanings or readings. 
                    Realizing that you forgot a kanji means you actually learned something because you at least remember seeing its shape before!
                </p>
                <br/>
                <p>
                    <strong>This is where <Link to="/language/kanjisearch">Kanji Search</Link> comes into play. 
                    Look up the kanji in question in the custom-designed dictionary and this will help refresh your memory of it and its mnemonic.
                    Before you know it, you will be recognizing and reading virtually all the kanji you have learned.</strong>
                </p>
                <br/><br/>


                <h5>What About Spaced Repetition Systems?</h5>
                <br/>
                <p>
                    There is a built-in spaced repetition system (SRS) concept into Kanji Remastered. 
                    Since complex Kanji are taught as products of simpler kanji, rather than fully broken-down into radicals, previously learned kanji are periodically revisited as you progress through the flashcards.
                    Kanji Remastered doesn't beleive SRS is necessary for learning kanji. 
                    Although SRS can be a very helpful tool for remembering things, unless kanji are encountered in everyday life, no type of memorization system will be powerful enough to prevent forgetting them. 
                    SRS will help retain kanji in short-term memory better than no SRS but it will do nothing for the very-long term. 
                    The goal of learning Kanji isn't to have to use an app for life to retain the info, it's to learn enough to get reading real Japanese as soon as possible.
                </p>
                <br/><br/>

                <h5>Now Go (Re)master Kanji!</h5>
                <span className="theory-remaster-kanji-text">頑張って下さい!</span>
            </div>
        </div>
    );
}