import React, { useContext, useEffect, useState }  from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import Odometer from 'react-odometerjs';
import { Context } from 'context/context';
import { Cards } from 'components';
// import { useAuth } from 'context/authContext';
import searchServices from 'middleware/searchServices';
import flashcardEngine from 'middleware/flashcardEngine';
import {
    DUMMY_CARD_NUMBER,
    FLASHCARD_TYPE,
    QUESTION_TYPE,
    CHALLENGE_THRESHOLD,
    NUM_KANJIS,
    dummyFlashcard,
} from 'constants/constants';
import {
    IThemedComponentProps,
    IContext,
    IFlashcard,
    IFlashcardRecord,
    IFlashcardHistory,
    IScoreAlert,
    IChallengeCorrectAnswers,
    QuestionType,
} from 'interfaces/interfaces';

export default function Flashcards({ darkMode }: IThemedComponentProps): JSX.Element {
    // FLASHCARDS
    const [flashcardDeck, setFlashcardDeck] = useState<IFlashcard[]>([]);
    const [challengeDeck, setChallengeDeck] = useState<IFlashcard[]>([]);
    const [flashcardRecord, setFlashcardRecord] = useState<IFlashcardRecord | undefined>(undefined);
    const [cardIndex, setCardIndex] = useState(0);
    const [tempCardIndex, setTempCardIndex] = useState(0);
    const [numberOfCards, setNumberOfCards] = useState(0);
    const [prevNumberOfCards, setPrevNumberOfCards] = useState(0);
    const [isCardFlipped, setCardFlipped] = useState(false);
    const [isCorrect, setCorrect] = useState(false);
    const [meaningTestResults, setMeaningTestResults] = useState<number[]>([]);
    const [kanjiTestResults, setKanjiTestResults] = useState<number[]>([]);
    const [onyomiTestResults, setOnyomiTestResults] = useState<number[]>([]);
    const [kunyomiTestResults, setKunyomiTestResults] = useState<number[]>([]);
    const [scoreAlerts, setScoreAlerts] = useState<IScoreAlert[]>([]);
    const [showModalExit, setShowModalExit] = useState(false);
    const [showModalChallenge, setShowModalChallenge] = useState(false);
    const [showModalSignIn, setShowModalSignIn] = useState(false);
    const [challengeCorrectAnswers, setChallengeCorrectAnswers] = useState<IChallengeCorrectAnswers>({} as IChallengeCorrectAnswers);
    const [alreadyChallenged, setAlreadyChallenged] = useState<string[]>([]);

    // const { userLoggedIn } = useAuth();
    const navigate = useNavigate();

    const {
        isStartFlashcards,
        setStartFlashcards,
        setFlashcardsPage,
        isChallengeRound,
        setChallengeRound,

        setTotalCardsSeen,
        setTotalCardsAnswered,
        setTotalCardsCorrect,
        setTotalScore,
        setTotalScoreDigits,

        setMeaningCardsCorrect,
        setKanjiCardsCorrect,
        setOnyomiCardsCorrect,
        setKunyomiCardsCorrect,

        setMeaningScore,
        setKanjiScore,
        setOnyomiScore,
        setKunyomiScore,
    } = useContext(Context) as IContext;

    useEffect(() => {
        for (let i = 0; i < 99999; i++) {
            window.clearInterval(i);
        }

        if (isCardFlipped && scoreAlerts.length) {
            // the time in setInterval should match the time in the CSS animation .scroller > span
            // Example: setInterval(() => {}, 2000) --> animation: slide 2s 1;

            const n = scoreAlerts.length;
            let counter = 0;
            const i = setInterval(() => {
                setScoreAlerts(scoreAlerts => [...scoreAlerts.slice(1)]);
                counter++;
                if (counter === n) {
                    clearInterval(i);
                }
            }, 2000);
        }
    }, [isCardFlipped]);

    useEffect(() => {
        setFlashcardsPage(true);
        const userFlashcardRecord = flashcardEngine.getUserFlashcardRecord();
        setFlashcardRecord(userFlashcardRecord);
    }, [flashcardDeck, challengeDeck, cardIndex]);

    const modalStyles = { backgroundColor: darkMode ? 'var(--mediumBgColor)': 'var(--lightGrayColor)' };
    const modalButtonStyles = { borderRadius: 2 };
    const modalHeaderStyles = {
        borderRadius: 0,
        borderBottom: darkMode ? 'var(--borderPrimaryDark)' : 'var(--borderPrimary)',
        backgroundColor: darkMode ? 'var(--darkBgColor)' : 'var(--whiteColor)'
    };
    const modalTitleStyles = {
        color: darkMode ? 'var(--mediumGrayColor)' : 'var(--blueColor)',
        font: 'var(--xLargeHeavyFont)'
    };
    const modalBodyStyles = {
        borderBottom: darkMode ? 'var(--borderPrimaryDark)' : 'var(--borderPrimary)',
        backgroundColor: darkMode ? 'var(--darkBgColor)' : 'var(--whiteColor)',
        color: darkMode ? 'var(--mediumGrayColor)' : 'var(--blueColor)',
        font: 'var(--xLargeHeavyFont)'
    };
    const modalFooterStyles = {
        borderRadius: 0,
        border: 0,
        backgroundColor: darkMode ? 'var(--darkBgColor)' : 'var(--whiteColor)'
    };

    const lastCard = flashcardDeck.length - 1;

    const {
        totalCardsSeen,
        totalCardsAnswered,
        totalCardsCorrect,
        totalScore,
        totalScoreDigits,

        meaningCardsCorrect,
        kanjiCardsCorrect,
        onyomiCardsCorrect,
        kunyomiCardsCorrect,

        meaningScore,
        kanjiScore,
        onyomiScore,
        kunyomiScore,
    } = flashcardEngine.getUserData();

    function processFlashcards(newFlashcards: IFlashcard[]): void {
        if (newFlashcards.length) {
            const processed: IFlashcard[] = [];

            newFlashcards.forEach((newFlashcard, index) => {
                const cardNumber = index + 1;
                if (cardNumber % (DUMMY_CARD_NUMBER - 1) === 0) {
                    // ADDS REGULAR CARD
                    processed.push(newFlashcard);
                    // ADDS ADVERTSING CARD
                    processed.push({
                        ...dummyFlashcard,
                        testKanjiData: {
                            ...dummyFlashcard.testKanjiData,
                            cardType: FLASHCARD_TYPE.advertising,
                        }
                    });
                } else {
                    // ADDS REGULAR CARD
                    processed.push(newFlashcard);
                }
            });

            // ADDS REVIEW CARD
            processed.push({
                ...dummyFlashcard,
                testKanjiData: {
                    ...dummyFlashcard.testKanjiData,
                    cardType: FLASHCARD_TYPE.flashcardsReview,
                }
            });

            setFlashcardDeck(processed);
        }
    }

    function processChallengeFlashcards(newFlashcards: IFlashcard[]): void {
        if (newFlashcards.length) {
            const processed: IFlashcard[] = [];

            newFlashcards.forEach(newFlashcard => {
                // ADDS REGULAR CARD
                processed.push(newFlashcard);
            });

            // ADDS SUCCESS CARD
            processed.push({
                ...dummyFlashcard,
                testKanjiData: {
                    ...dummyFlashcard.testKanjiData,
                    cardType: FLASHCARD_TYPE.challengeSucess,
                }
            });

            // ADDS FAILURE CARD
            processed.push({
                ...dummyFlashcard,
                testKanjiData: {
                    ...dummyFlashcard.testKanjiData,
                    cardType: FLASHCARD_TYPE.challengeFailure,
                }
            });

            setChallengeDeck(processed);
        }
    }

    function handleClickStart(): void {
        // if (userLoggedIn) {
        if (true) {
            const {
                flashcards,
                index,
                originalNumOfCards,
                alreadyChallenged: alreadyChallengedCached,
                isCached,
            } = searchServices.getFlashcardSet();

            if (isCached) {
                setFlashcardDeck(flashcards);
                setNumberOfCards(originalNumOfCards);
                setAlreadyChallenged(alreadyChallengedCached);
            } else {
                processFlashcards(flashcards);
                setNumberOfCards(flashcards.length);
            }

            // alert(`flashcards: ${flashcards.slice(0, 20).map(({ testKanjiData }) => testKanjiData.meaning).join(',')}`);

            setStartFlashcards(true);
            setCardIndex(index);

            setTotalCardsSeen(totalCardsSeen);
            setTotalCardsAnswered(totalCardsAnswered);
            setTotalCardsCorrect(totalCardsCorrect);
            setTotalScore(totalScore);
            setTotalScoreDigits(totalScoreDigits);

            setMeaningCardsCorrect(meaningCardsCorrect);
            setKanjiCardsCorrect(kanjiCardsCorrect);
            setOnyomiCardsCorrect(onyomiCardsCorrect);
            setKunyomiCardsCorrect(kunyomiCardsCorrect);

            setMeaningScore(meaningScore);
            setKanjiScore(kanjiScore);
            setOnyomiScore(onyomiScore);
            setKunyomiScore(kunyomiScore);
        }
        // } else {
        //     setShowModalSignIn(true);
        // }
    }

    function handleClickContinue(isSuccess: boolean): void {
        setChallengeDeck([]);
        setChallengeRound(false);
        setStartFlashcards(true);
        setNumberOfCards(prevNumberOfCards);

        if (isSuccess) {
            if (flashcardRecord?.highestSeenKanjiId === NUM_KANJIS) {
                setFlashcardDeck([{
                    ...dummyFlashcard,
                    testKanjiData: {
                        ...dummyFlashcard.testKanjiData,
                        cardType: FLASHCARD_TYPE.flashcardsFinished,
                    }
                }]);

                setCardIndex(0);
            } else {
                setCardIndex(tempCardIndex);
            }
        } else {
            // tempCardIndex + 1 because flashcards will continue starting form the next card
            setCardIndex(tempCardIndex + 1);
        }
    }

    function standardDeviation(arr: number[]): number {
        // Creating the mean with Array.reduce
        const mean = arr.reduce((acc, curr) => {
            return acc + curr;
        }, 0) / arr.length;
    
        // Assigning (value - mean) ^ 2 to
        // every array item
        arr = arr.map((k) => {
            return (k - mean) ** 2;
        });
    
        // Calculating the sum of updated array 
        const sum = arr.reduce((acc, curr) => acc + curr, 0);
    
        // Calculating the variance
        // const variance = sum / arr.length
    
        // Returning the standard deviation
        return Math.sqrt(sum / arr.length);
    }

    interface IAverages {
        meaningsAverage: number;
        kanjiAverage: number;
        onyomiAverage: number;
        kunyomiAverage: number;
        meaningsStd: number;
        kanjiStd: number;
        onyomiStd: number;
        kunyomiStd: number;
    }

    function questionTypeAverages(allCards: IFlashcardHistory[]): IAverages {
        const meaningsAvgs: number[] = [];
        const kanjiAvgs: number[] = [];
        const onyomiAvgs: number[] = [];
        const kunyomiAvgs: number[] = [];

        let meaningsTimesSeen = 0;
        let meaningsTimesCorrect = 0;
        let kanjiTimesSeen = 0;
        let kanjiTimesCorrect = 0;
        let onyomiTimesSeen = 0;
        let onyomiTimesCorrect = 0;
        let kunyomiTimesSeen = 0;
        let kunyomiTimesCorrect = 0;

        allCards.forEach(card => {
            if (card.questionType === QUESTION_TYPE.meaning) {
                meaningsTimesSeen += card.timesSeen;
                meaningsTimesCorrect += card.timesCorrect;
                meaningsAvgs.push(meaningsTimesCorrect / meaningsTimesSeen);
            }

            if (card.questionType === QUESTION_TYPE.kanji) {
                kanjiTimesSeen += card.timesSeen;
                kanjiTimesCorrect += card.timesCorrect;
                kanjiAvgs.push(kanjiTimesCorrect / kanjiTimesSeen);
            }

            if (card.questionType === QUESTION_TYPE.onyomi) {
                onyomiTimesSeen += card.timesSeen;
                onyomiTimesCorrect += card.timesCorrect;
                onyomiAvgs.push(onyomiTimesSeen / onyomiTimesCorrect);
            }

            if (card.questionType === QUESTION_TYPE.kunyomi) {
                kunyomiTimesSeen += card.timesSeen;
                kunyomiTimesCorrect += card.timesCorrect;
                kunyomiAvgs.push(kunyomiTimesCorrect / kunyomiTimesSeen);
            }
        });

        return {
            meaningsAverage: meaningsTimesCorrect / meaningsTimesSeen || 0,
            kanjiAverage: kanjiTimesCorrect / kanjiTimesSeen || 0,
            onyomiAverage: onyomiTimesCorrect / onyomiTimesSeen || 0,
            kunyomiAverage: kunyomiTimesCorrect / kunyomiTimesSeen || 0,
            meaningsStd: standardDeviation(meaningsAvgs) || 0,
            kanjiStd: standardDeviation(kanjiAvgs) || 0,
            onyomiStd: standardDeviation(onyomiAvgs) || 0,
            kunyomiStd: standardDeviation(kunyomiAvgs) || 0,
        };
    }

    function thresholds(questionType: QuestionType, allCards: IFlashcardHistory[]): { hardThreshold: number; mediumThreshold: number; } {
        const {
            meaningsAverage,
            kanjiAverage,
            onyomiAverage,
            kunyomiAverage,
            meaningsStd,
            kanjiStd,
            onyomiStd,
            kunyomiStd,
        } = questionTypeAverages(allCards);

        let hardThreshold = 0;
        let mediumThreshold = 0;

        if (questionType === QUESTION_TYPE.meaning) {
            hardThreshold = meaningsAverage - meaningsStd;
            mediumThreshold = meaningsAverage + meaningsStd;
        }
        
        if (questionType === QUESTION_TYPE.kanji) {
            hardThreshold = kanjiAverage - kanjiStd;
            mediumThreshold = kanjiAverage + kanjiStd;
        }

        if (questionType === QUESTION_TYPE.onyomi) {
            hardThreshold = onyomiAverage - onyomiStd;
            mediumThreshold = onyomiAverage + onyomiStd;
        }

        if (questionType === QUESTION_TYPE.kunyomi) {
            hardThreshold = kunyomiAverage - kunyomiStd;
            mediumThreshold = kunyomiAverage + kunyomiStd;
        }

        return { hardThreshold, mediumThreshold };
    }

    function updateFlashcardRecord(
        flashcard: IFlashcard,
        isCorrect: boolean = false,
        answerPoints: number = 0,
        alerts: IScoreAlert[] = [],
    ): void {
        // SET SCORE ALERT MESSAGES
        setScoreAlerts(alerts);
        /////////////////////////


        const { flashcardHistory } = flashcard;
        const {
            highestSeenKanjiId,
            challengedCards,
            hardCards,
            mediumCards,
            easyCards,
        } = flashcardRecord as IFlashcardRecord;

        // highestSeenKanjiId
        let newHighestSeenKanjiId = highestSeenKanjiId;
        const { cardType } = flashcard.testKanjiData;

        if (cardType === FLASHCARD_TYPE.newMeaning ||
            cardType === FLASHCARD_TYPE.newKanji ||
            cardType === FLASHCARD_TYPE.newOnyomi ||
            cardType === FLASHCARD_TYPE.newKunyomi
        ) {
            newHighestSeenKanjiId = flashcard.testKanjiData.id;
        }

        const {
            UID,
            timesSeen,
            timesCorrect,
            questionType,
            value,
        } = flashcardHistory;
        const average = timesCorrect / timesSeen;

        function removeCardFromPile(newCards: IFlashcardHistory[]): IFlashcardHistory[] {
            // remvoes the flashcard from a difficulty set if it is in there
            let tempNewCards = [...newCards];
            newCards.every((card, index) => {
                if (card.UID === UID && card.questionType === questionType && card.value === value) {
                    tempNewCards = [...tempNewCards.slice(0, index), ...tempNewCards.slice(index + 1)];
                    return false;
                }

                return true;
            });

            return tempNewCards;
        }

        // challengedCards
        let newChallengedCards = [...challengedCards];

        // hardCards
        let newHardCards = removeCardFromPile(hardCards);

        // mediumCards
        let newMediumCards = removeCardFromPile(mediumCards);

        // easyCards
        let newEasyCards = removeCardFromPile(easyCards);

        // calculates thresholds to decide if a card should be reclassified as hard, medium or easy based on previous statistics.
        const allCards = [...challengedCards, ...hardCards, ...mediumCards, ...easyCards];
        const { hardThreshold, mediumThreshold } = thresholds(questionType, allCards);

        if (questionType !== QUESTION_TYPE.study) {
            const updatedFlashcardHistory = {
                ...flashcardHistory,
                timesSeen: flashcardHistory.timesSeen + 1,
                timesCorrect: isCorrect ? flashcardHistory.timesCorrect + 1 : flashcardHistory.timesCorrect,
                score: isCorrect ? flashcardHistory.score + answerPoints : flashcardHistory.score - answerPoints,
            };

            if (timesSeen < 3) {
                newHardCards = [updatedFlashcardHistory, ...newHardCards];
            } else if (average < hardThreshold) {
                newHardCards.push(updatedFlashcardHistory);
            } else if (average < mediumThreshold || timesSeen < 12) {
                newMediumCards.push(updatedFlashcardHistory);
            } else {
                newEasyCards.push(updatedFlashcardHistory);
            }
        }

        // SET USER DATA IN APP STATE
        setFlashcardRecord({
            ...flashcardRecord as IFlashcardRecord,
            highestSeenKanjiId: newHighestSeenKanjiId,
            challengedCards: newChallengedCards,
            hardCards: newHardCards,
            mediumCards: newMediumCards,
            easyCards: newEasyCards,
        });

        // SAVE USER DATA IN DB
        flashcardEngine.setUserFlashcardRecord({
            ...flashcardRecord as IFlashcardRecord,
            highestSeenKanjiId: newHighestSeenKanjiId,
            challengedCards: newChallengedCards,
            hardCards: newHardCards,
            mediumCards: newMediumCards,
            easyCards: newEasyCards,
        });
    }

    function updateChallengedCards(): void {
        const { UID, questionType } = flashcardDeck[tempCardIndex].flashcardHistory; // original flashcard challenged 

        const {
            unseenCards,
            challengedCards,
            hardCards,
            mediumCards,
            easyCards,
        } = flashcardRecord as IFlashcardRecord;

        // challengedCards
        let tempChallengedCards = [...challengedCards];

        function removeCardFromPile(oldCards: IFlashcardHistory[]): IFlashcardHistory[] {
            // removes a flashcard from a difficulty set if it is for a sucessfully challenged kanji
            const tempNewCards: IFlashcardHistory[] = [];

            oldCards.forEach(card => {
                if (card.UID === UID) {
                    tempChallengedCards.push(card);
                } else {
                    tempNewCards.push(card);
                }
            });

            return tempNewCards;
        }

        // hardCards
        let newUnseenCards = removeCardFromPile(unseenCards);

        // hardCards
        let newHardCards = removeCardFromPile(hardCards);

        // mediumCards
        let newMediumCards = removeCardFromPile(mediumCards);

        // easyCards
        let newEasyCards = removeCardFromPile(easyCards);

        // flashcards
        let newFlashcards: IFlashcard[] = [];
        let newCardIndex = 0;
        let c = 0;
    
        flashcardDeck.forEach(flashcard => {
            // TODO questionType KANJI or RADICALS????
            // console.log({
            //     fUID: flashcard.flashcardHistory.UID,
            //     UID,
            //     fQT: flashcard.flashcardHistory.questionType,
            //     questionType
            // });

            if (flashcard.flashcardHistory.UID === UID && flashcard.flashcardHistory.questionType === questionType) {
                newCardIndex = c;
            }

            if (flashcard.flashcardHistory.UID !== UID) {
                newFlashcards.push(flashcard);
                c++;
            }
        });

        setTempCardIndex(newCardIndex);
        setFlashcardDeck(newFlashcards);

         // SET USER DATA IN APP STATE
         setFlashcardRecord({
            ...flashcardRecord as IFlashcardRecord,
            unseenCards: newUnseenCards,
            challengedCards: tempChallengedCards,
            hardCards: newHardCards,
            mediumCards: newMediumCards,
            easyCards: newEasyCards,
        });

        // SAVE USER DATA IN DB
        flashcardEngine.setUserFlashcardRecord({
            ...flashcardRecord as IFlashcardRecord,
            unseenCards: newUnseenCards,
            challengedCards: tempChallengedCards,
            hardCards: newHardCards,
            mediumCards: newMediumCards,
            easyCards: newEasyCards,
        });

        // console.log({
        //     is_in_unseenCards: unseenCards.filter(card => card.UID === UID),
        //     is_in_challengedCards: challengedCards.filter(card => card.UID === UID),
        //     tempChallengedCards,
        //     newHardCards,
        //     newMediumCards,
        //     newEasyCards,
        // });
    }

    function goToNextCard(): void {
        // if not yet at last card
        if (cardIndex < flashcardDeck.length - 1) {
            setCardIndex(cardIndex => cardIndex + 1);
        }
    }

    function handleClickNextCard(): void {
        goToNextCard();
        setCardFlipped(false);
        setCorrect(false);
        setScoreAlerts([]);
    }

    function resetFlashCardState(): void {
        setStartFlashcards(false);
        setChallengeRound(false);
        setCardFlipped(false);
        setFlashcardDeck([]);
        setChallengeDeck([]);
        setAlreadyChallenged([]);
    }

    function handleModalButtonExit(): void {
        localStorage.setItem('flashcards', JSON.stringify({
            flashcards: flashcardDeck,
            index: cardIndex + 1,
            originalNumOfCards: numberOfCards,
            alreadyChallenged,
            isCached: true,
        }));

        resetFlashCardState();
        setShowModalExit(false);
    }

    function handleCloseModals(): void {
        setShowModalExit(false);
        setShowModalChallenge(false);
        setShowModalSignIn(false);
    }

    function handleModalButtonKeepPlaying(): void {
        setShowModalExit(false);
    }

    function handleClickExit(): void {
        if (cardIndex === flashcardDeck.length - 1) {
            localStorage.setItem('flashcards', '');
            resetFlashCardState();
        } else {
            setShowModalExit(true);
        }
    }

    function handleClickChallenge(): void {
        setShowModalChallenge(true);
    }

    function handleClickNoChallenge(): void {
        setShowModalChallenge(false);
        goToNextCard();
    }

    function handleModalYesChallenge(): void {
        setShowModalChallenge(false);
        setStartFlashcards(false);
        setChallengeRound(true);
        setTempCardIndex(cardIndex);
        setCardIndex(0);
        const challengeFlashcards = flashcardEngine.getChallengeFlashcards(flashcardDeck[cardIndex]);
        processChallengeFlashcards(challengeFlashcards);
        setPrevNumberOfCards(numberOfCards);
        setNumberOfCards(challengeFlashcards.length);
        setAlreadyChallenged(alreadyChallenged =>  [...alreadyChallenged, flashcardDeck[cardIndex].flashcardHistory.UID]);
    }

    function handleModalSignIn(): void {
        navigate('/auth/signin');
    }

    function setChallengeStatus(isSuccess: boolean): void {
        if (isSuccess) {
            updateChallengedCards();
        } else {
            const failureCardIndex = challengeDeck.length - 1;
            setCardIndex(failureCardIndex);
        }
    }

    function isValidCardIndex(): boolean {
        // first card is always valid
        if (cardIndex === 0) {
            return true;
        }

        if (isStartFlashcards) {
            // last card should not have a card number
            if (cardIndex === flashcardDeck.length - 1) {
                return false;
            }

            // advertisng card should not have a card number
            if ((cardIndex + 1) % DUMMY_CARD_NUMBER === 0) {
                return false;
            }
        }

        if (isChallengeRound) {
            // last card should not have a card number
            if (cardIndex === challengeDeck.length - 1 || cardIndex === challengeDeck.length - 2) {
                return false;
            }
        }

        // every regular card
        return true;
    }

    function cardsRemaining(): number {
        // returns the  number of flashcards minus actual flashcard card plus the number of advertsing card sadded back
        const advertsingCardoffset = Math.floor(cardIndex / DUMMY_CARD_NUMBER);
        const cardNumber = cardIndex + 1;
        return numberOfCards - cardNumber + advertsingCardoffset;
    }

    function isChallengeEligible(flashcard: IFlashcard): boolean {
        const { testKanjiData, flashcardHistory } = flashcard;

        if (alreadyChallenged.includes(flashcardHistory.UID)) {
            return false;
        }

        const {
            hardCards,
            mediumCards,
            easyCards,
        } = flashcardRecord as IFlashcardRecord;

        let correctCount = 0;
        const seenCards = [...hardCards, ...mediumCards, ...easyCards];

        if (testKanjiData.kunyomi.length) {
            seenCards.forEach(card => {
                if (card.UID === flashcardHistory.UID && card.questionType === QUESTION_TYPE.kunyomi) {
                    correctCount += card.timesCorrect;
                }
            });
        } else if (testKanjiData.onyomi.length) {
            seenCards.forEach(card => {
                if (card.UID === flashcardHistory.UID && card.questionType === QUESTION_TYPE.onyomi) {
                    correctCount += card.timesCorrect;
                }
            });
        } else {
            seenCards.forEach(card => {
                if (card.UID === flashcardHistory.UID && card.questionType === QUESTION_TYPE.radicals) {
                    correctCount += card.timesCorrect;
                }
            });
        }

        return correctCount >= CHALLENGE_THRESHOLD;
    }

    interface IFlashcardProps {
        index: number;
        flashcard: IFlashcard;
    }

    function Flashcard({ index, flashcard }: IFlashcardProps): JSX.Element {
        switch (flashcard.testKanjiData.cardType) {
            case FLASHCARD_TYPE.flashcardsInstructions:
                return <Cards.InstructionsFlashcard handleClickStart={handleClickStart}/>;

            case FLASHCARD_TYPE.advertising:
                return <Cards.Advertising handleClickNextCard={handleClickNextCard}/>;

            case FLASHCARD_TYPE.quizMeaning:
            case FLASHCARD_TYPE.quizKanji:
            case FLASHCARD_TYPE.quizRadicals:
            case FLASHCARD_TYPE.quizOnyomi:
            case FLASHCARD_TYPE.quizKunyomi:
                const lastCardIndex = isStartFlashcards ?
                    flashcardDeck.length - 2 : // last flashcard is the second-to-last one, one before the review page
                    challengeDeck.length - 3; // last flashcard is third-to-last one, before success and faiure pages.

                return (
                    <Cards.QuizFlashcard
                        index={index}
                        flashcard={flashcard}
                        lastCardIndex={lastCardIndex}
                        setFlashcardDeck={setFlashcardDeck}
                        updateFlashcardRecord={updateFlashcardRecord}
                        numOflashcards={flashcardDeck.length}
                        isCardFlipped={isCardFlipped}
                        setCardFlipped={setCardFlipped}
                        goToNextCard={goToNextCard}
                        isCorrect={isCorrect}
                        setCorrect={setCorrect}
                        isChallengeRound={isChallengeRound}
                        setChallengeStatus={setChallengeStatus}
                        challengeCorrectAnswers={challengeCorrectAnswers}
                        setChallengeCorrectAnswers={setChallengeCorrectAnswers}
                        meaningTestResults={meaningTestResults}
                        setMeaningTestResults={setMeaningTestResults}
                        kanjiTestResults={kanjiTestResults}
                        setKanjiTestResults={setKanjiTestResults}
                        onyomiTestResults={onyomiTestResults}
                        setOnyomiTestResults={setOnyomiTestResults}
                        kunyomiTestResults={kunyomiTestResults}
                        setKunyomiTestResults={setKunyomiTestResults}
                        handleClickNextCard={handleClickNextCard}
                        darkMode={darkMode}
                    />
                );

            case FLASHCARD_TYPE.newMeaning:
            case FLASHCARD_TYPE.newKanji:
            case FLASHCARD_TYPE.newOnyomi:
            case FLASHCARD_TYPE.newKunyomi:
            case FLASHCARD_TYPE.newRadical:
            case FLASHCARD_TYPE.newPseudoRadical:
            case FLASHCARD_TYPE.newProtoRadical:
                return (
                    <Cards.NewFlashcard
                        index={index}
                        flashcard={flashcard}
                        setFlashcardDeck={setFlashcardDeck}
                        updateFlashcardRecord={updateFlashcardRecord}
                        numOflashcards={flashcardDeck.length}
                        isCardFlipped={isCardFlipped}
                        setCardFlipped={setCardFlipped}
                        handleClickNextCard={handleClickNextCard}
                        darkMode={darkMode}
                    />
                );

            case FLASHCARD_TYPE.flashcardsReview:
                return (
                    <Cards.ReviewFlashcard
                        flashcardDeck={flashcardDeck}
                        meaningTestResults={meaningTestResults}
                        kanjiTestResults={kanjiTestResults}
                        onyomiTestResults={onyomiTestResults}
                        kunyomiTestResults={kunyomiTestResults}
                        flashcardRecord={flashcardRecord as IFlashcardRecord}
                    />
                );

            case FLASHCARD_TYPE.flashcardsFinished:
                return <Cards.FinishedFlashcard/>;

            case FLASHCARD_TYPE.challengeSucess:
                return <Cards.ChallengeSucess handleClickContinue={handleClickContinue}/>;

            case FLASHCARD_TYPE.challengeFailure:
                return <Cards.ChallengeFailure handleClickContinue={handleClickContinue}/>;

            default:
                return <Cards.Error/>;
        }
    }

    return (
        <div className="page-container flashcards">
            <div className="flashcards-container">
                {!isStartFlashcards && !isChallengeRound &&
                    <div className="flashcards-top-panel">
                        <Cards.InstructionsFlashcard handleClickStart={handleClickStart}/>
                    </div>
                }

                {isStartFlashcards && flashcardDeck.length > 0 &&
                    <div className="flashcards-top-panel">
                        <Flashcard index={cardIndex} flashcard={flashcardDeck[cardIndex]}/>
                    </div>
                }

                {isChallengeRound && challengeDeck.length > 0 &&
                    <div className="flashcards-top-panel">
                        <Flashcard index={cardIndex} flashcard={challengeDeck[cardIndex]}/>
                    </div>
                }

                {(isStartFlashcards || isChallengeRound) &&
                    <div className="flashcards-bottom-panel">
                        <div className="score-bar">
                            <div className="score meaning">
                                <Odometer value={meaningScore} format="(,ddd)" duration={1000}/>
                            </div>
                            <div className="score kanji">
                                <Odometer value={kanjiScore} format="(,ddd)" duration={1000}/>
                            </div>
                            <div className="score onyomi">
                                <Odometer value={onyomiScore} format="(,ddd)" duration={1000}/>
                            </div>
                            <div className="score kunyomi">
                                <Odometer value={kunyomiScore} format="(,ddd)" duration={1000}/>
                            </div>
                        </div>
                        <div className="score-alerts">
                            <div className="scroller">
                                {isCardFlipped && scoreAlerts.length > 0 && <span className={scoreAlerts[0].type}>{scoreAlerts[0].text}</span>}
                            </div>
                        </div>
                        <div className="controls">
                            <div className="control-item">
                                {isValidCardIndex() && `${cardsRemaining()} Cards Left`}
                            </div>
                            <div className="control-item">
                                {(isCardFlipped || cardIndex === lastCard) &&
                                    <button className="exit-flashcards" onClick={handleClickExit}>
                                        Exit
                                    </button>
                                }

                                {!isChallengeRound && !isCardFlipped && cardIndex !== lastCard && isChallengeEligible(flashcardDeck[cardIndex]) &&
                                    <button className="challenge-kanji" onClick={() => handleClickChallenge()}>
                                        Challenge This Kanji
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>

            <Modal show={showModalExit} onHide={handleCloseModals} key="Modal_Exit">
                <Modal.Header style={modalHeaderStyles}>
                    <Modal.Title style={modalTitleStyles}>EXIT FLASHCARDS?</Modal.Title>
                </Modal.Header>
                <Modal.Body style={modalBodyStyles}>
                    <h4>Are you sure you want to exit? Your progress will be saved.</h4>
                </Modal.Body>
                <Modal.Footer style={modalFooterStyles}>
                    <Button variant={darkMode ? 'secondary' : 'light'} style={modalButtonStyles} onClick={handleModalButtonKeepPlaying}>
                        Keep Playing
                    </Button>
                    <Button variant="danger" style={modalButtonStyles} onClick={handleModalButtonExit}>
                        Exit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalChallenge} onHide={handleCloseModals} style={modalStyles} key="Modal_Challenge">
                <Modal.Header style={modalHeaderStyles}>
                    <Modal.Title style={modalTitleStyles}>CHALLENGE THIS KANJI?</Modal.Title>
                </Modal.Header>
                <Modal.Body style={modalBodyStyles}>
                    <h4>Do you already know this kanji?</h4>
                    <p className="modal-p">You'll have to answer ALL the questions correctly in the challenge round to permanently remove this kanji forom the deck.</p>
                    <p className="modal-p">You will earn extra bonus points if you do!</p>
                </Modal.Body>
                <Modal.Footer style={modalFooterStyles}>
                    <Button variant={darkMode ? 'secondary' : 'light'} style={modalButtonStyles} onClick={handleClickNoChallenge}>
                        No
                    </Button>
                    <Button variant="danger" style={modalButtonStyles} onClick={handleModalYesChallenge}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalSignIn} onHide={handleCloseModals} key="Modal_SignIn">
                <Modal.Header style={modalHeaderStyles}>
                    <Modal.Title style={modalTitleStyles}>SIGN IN TO CONTINUE</Modal.Title>
                </Modal.Header>
                <Modal.Body style={modalBodyStyles}>
                    <h4>Do you already have an account?</h4>
                    <p className="modal-p">Sign in or <Link className="register-link" to={'/auth/register'}>Register</Link> to start the Kanji Remastered flashcards.</p>
                </Modal.Body>
                <Modal.Footer style={modalFooterStyles}>
                    <Button variant={darkMode ? 'secondary' : 'light'} style={modalButtonStyles} onClick={() => setShowModalSignIn(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" style={modalButtonStyles} onClick={handleModalSignIn}>
                        Sign In
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};