import { db } from 'firebaseConfig/firebase';
import { ref, set } from 'firebase/database';
import searchServices from './searchServices';
import { QUESTION_TYPE, EASY_THRESHOLD, NO_READING } from 'constants/constants';
import {
    ITestKanjiData,
    IUserData,
    ISetUserData,
    ITestChoices,
    IFlashcardHistory,
    IFlashcardRecord,
    IKanjiData,
    IFlashcard,
    IConvertibleCard,
} from 'interfaces/interfaces';
import { shuffle } from 'helpers/helpers';

const {
    convertToTestSet,
    kanjis,
    // getStandardKanjiSet,
} = searchServices;

function createFlashcardHistories(kanji: IKanjiData, flashcardHistories: IFlashcardHistory[]): void {
    const baseData: Pick<IFlashcardHistory, 'UID' | 'timesSeen' | 'timesCorrect'> = {
        UID: kanji.UID,
        timesSeen: 0,
        timesCorrect: 0,
    }

    flashcardHistories.push({
        ...baseData,
        questionType: QUESTION_TYPE.kanji,
        value: kanji.kanji,
        score: 0,
    })

    for (let meaning of kanji.meanings) {
        flashcardHistories.push({
            ...baseData,
            questionType: QUESTION_TYPE.meaning,
            value: meaning,
            score: 0,
        });
    }

    if (kanji.onyomi.length) {
        for (let onyomi of kanji.onyomi) {
            flashcardHistories.push({
                ...baseData,
                questionType: QUESTION_TYPE.onyomi,
                value: onyomi,
                score: 0,
            });
        }
    } else {
        flashcardHistories.push({
            ...baseData,
            questionType: QUESTION_TYPE.onyomi,
            value: NO_READING,
            score: 0,
        })
    }

    if (kanji.kunyomi.length) {
        for (let onyomi of kanji.kunyomi) {
            flashcardHistories.push({
                ...baseData,
                questionType: QUESTION_TYPE.kunyomi,
                value: onyomi,
                score: 0,
            });
        }
    } else {
        flashcardHistories.push({
            ...baseData,
            questionType: QUESTION_TYPE.kunyomi,
            value: NO_READING,
            score: 0,
        })
    }
}

function fullFlashcardSet(): IFlashcardHistory[] {
    const flashcardHistories: IFlashcardHistory[] = [];

    for (let kanji of kanjis) {
        createFlashcardHistories(kanji, flashcardHistories)
    }

    return flashcardHistories;
}

function getUserData(): IUserData {
    const userDataJSON = localStorage.getItem('user-data');
    return userDataJSON ?
        JSON.parse(userDataJSON) :
        {

            totalCardsSeen: 0,
            totalCardsAnswered: 0,
            totalCardsCorrect: 0,
            totalScore: 0,
            totalScoreDigits: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

            meaningCardsSeen: 0,
            kanjiCardsSeen: 0,
            onyomiCardsSeen: 0,
            kunyomiCardsSeen: 0,

            meaningCardsCorrect: 0,
            kanjiCardsCorrect: 0,
            onyomiCardsCorrect: 0,
            kunyomiCardsCorrect: 0,

            meaningScore: 0,
            kanjiScore: 0,
            onyomiScore: 0,
            kunyomiScore: 0,
        };
}

function setUserData({
    totalCardsSeen,
    totalCardsAnswered,
    totalCardsCorrect,
    totalScore,
    totalScoreDigits,

    meaningCardsSeen,
    kanjiCardsSeen,
    onyomiCardsSeen,
    kunyomiCardsSeen,

    meaningCardsCorrect,
    kanjiCardsCorrect,
    onyomiCardsCorrect,
    kunyomiCardsCorrect,

    meaningScore,
    kanjiScore,
    onyomiScore,
    kunyomiScore,

    currentUser,
}: ISetUserData): void {
    // const db = getDatabase(app);
    // const reference = ref(db, 'users/12345');
    // const newDocRef = push(reference);
    // console.log('setUserData(1)', { currentUser, reference });
    // set(reference, {
    //     totalCardsSeen: 3,
    //     totalCardsAnswered: 3,
    //     totalCardsCorrect: 3,
    //     totalScore: 3,
    //     totalScoreDigits: 3,
    // });
        // .then(() => {
        //     console.log('data saved successfully');
        // })
        // .catch((error) => {
        //     console.error({ error });
        // });

    // console.log('setUserData(2)');
    const userData = getUserData();
    const newUserData: ISetUserData = {
        ...userData,
        totalCardsSeen,
        totalCardsAnswered,
        totalCardsCorrect,
        totalScore,
        totalScoreDigits,
    };

    if (meaningCardsSeen) {
        newUserData.meaningCardsSeen = meaningCardsSeen;
    }

    if (kanjiCardsSeen) {
        newUserData.kanjiCardsSeen = kanjiCardsSeen;
    }

    if (onyomiCardsSeen) {
        newUserData.onyomiCardsSeen = onyomiCardsSeen;
    }

    if (kunyomiCardsSeen) {
        newUserData.kunyomiCardsSeen = kunyomiCardsSeen;
    }

    if (meaningCardsCorrect) {
        newUserData.meaningCardsCorrect = meaningCardsCorrect;
    }

    if (kanjiCardsCorrect) {
        newUserData.kanjiCardsCorrect = kanjiCardsCorrect;
    }

    if (onyomiCardsCorrect) {
        newUserData.onyomiCardsCorrect = onyomiCardsCorrect;
    }

    if (kunyomiCardsCorrect) {
        newUserData.kunyomiCardsCorrect = kunyomiCardsCorrect;
    }

    if (meaningScore) {
        newUserData.meaningScore = meaningScore;
    }

    if (kanjiScore) {
        newUserData.kanjiScore = kanjiScore;
    }

    if (onyomiScore) {
        newUserData.onyomiScore = onyomiScore;
    }

    if (kunyomiScore) {
        newUserData.kunyomiScore = kunyomiScore;
    }

    localStorage.setItem('user-data', JSON.stringify(newUserData));
}

function getUserFlashcardRecord(): IFlashcardRecord {
    const flashcardRecordJSON = localStorage.getItem('user-flashcard-record');
    return flashcardRecordJSON ?
        JSON.parse(flashcardRecordJSON) :
        {
            highestSeenKanjiId: 0,
            unseenCards: fullFlashcardSet(),
            challengedCards: [],
            hardCards: [],
            mediumCards: [],
            easyCards:[],
        };
}

function setUserFlashcardRecord(newFlashcardRecord: IFlashcardRecord): void {
    localStorage.setItem('user-flashcard-record', JSON.stringify(newFlashcardRecord));
}

function makeFlashcards(): IFlashcard[] {
    const NUM_NEW_KANJI = 6;
    const NUM_CARDS = 100;
    const numCardsToAdd = NUM_CARDS - (2 * NUM_NEW_KANJI);
    const numHardCards = Math.floor(.5 * numCardsToAdd); // .096 = 1 STDEV x .60
    const numMediumCards = Math.floor(.3 * numCardsToAdd); // .204 = 2 STDEV x .30
    const numEasyCards = numCardsToAdd - numHardCards - numMediumCards; // .016 = 1 STDEV x .10

    const flashcardRecord = getUserFlashcardRecord();

    let {
        highestSeenKanjiId,
        unseenCards,
        hardCards,
        mediumCards,
        easyCards,
    } = flashcardRecord;

    const newKanjiData: IKanjiData[] = [];
    const tempKanjiData: IKanjiData[] = [];
    const tempFlashcardHistories: IFlashcardHistory[] = [];
    let currUnseenCards = [...unseenCards];

    function hasNoOnyomiReadings(card: IFlashcardHistory): boolean {
        // finds out if a particualr card question type is 'kanji' but it does not have any onyomi readings
        const kanji = kanjis.find(({ UID }) => UID === card.UID);

        if (card.questionType === QUESTION_TYPE.kanji && !kanji?.onyomi.length) {
            return true;
        }

        return false;
    }

    function newTypes(card: IFlashcardHistory): IFlashcardHistory[] {
        let newTypeCards: IFlashcardHistory[] = [];
        let tempUnseenCards: IFlashcardHistory[] = [];

        if (card.questionType === QUESTION_TYPE.meaning) {
            for (let unseen of currUnseenCards) {
                if (unseen.UID === card.UID && unseen.questionType === QUESTION_TYPE.kanji) {
                    newTypeCards.push(unseen);
                }
            }

            if (newTypeCards.length) {
                // remove new found cards from unseen cards
                for (let newType of newTypeCards) {
                    currUnseenCards.forEach(unseen => {
                        if (newType.UID !== unseen.UID) {
                            tempUnseenCards.push(unseen);
                        } else if (newType.UID === unseen.UID && unseen.questionType !== QUESTION_TYPE.kanji) {
                            tempUnseenCards.push(unseen);
                        }
                    });
                }

                currUnseenCards = [...tempUnseenCards];
                return newTypeCards;
            }
        }

        if (card.questionType === QUESTION_TYPE.kanji) {
            for (let unseen of unseenCards) {
                if (unseen.UID === card.UID && unseen.questionType === QUESTION_TYPE.onyomi) {
                    newTypeCards.push(unseen);
                    console.log('FOUND CORRECTLY KANJI > ONYOMI', { card, unseen });
                }
            }

            if (newTypeCards.length) {
                // remove new found cards from unseen cards
                for (let newType of newTypeCards) {
                    currUnseenCards.forEach(unseen => {
                        if (newType.UID !== unseen.UID) {
                            tempUnseenCards.push(unseen);
                        } else if (newType.UID === unseen.UID && unseen.questionType !== QUESTION_TYPE.onyomi) {
                            tempUnseenCards.push(unseen);
                        }
                    });
                }

                currUnseenCards = [...tempUnseenCards];
                return newTypeCards;
            }
        }

        // if card question type is ONYOMI OR if card question type is KANJI but there are no onyomi readings
        if (card.questionType === QUESTION_TYPE.onyomi || hasNoOnyomiReadings(card)) {
            for (let unseen of unseenCards) {
                if (unseen.UID === card.UID && unseen.questionType === QUESTION_TYPE.kunyomi) {
                    newTypeCards.push(unseen);
                    console.log('FOUND CORRECTLY ONYOMI > KUNYOMI', { card, unseen });
                }
            }

            if (newTypeCards.length) {
                // remove new found cards from unseen cards
                for (let newType of newTypeCards) {
                    currUnseenCards.forEach(unseen => {
                        if (newType.UID !== unseen.UID) {
                            tempUnseenCards.push(unseen);
                        } else if (newType.UID === unseen.UID && unseen.questionType !== QUESTION_TYPE.kunyomi) {
                            tempUnseenCards.push(unseen);
                        }
                    });
                }

                currUnseenCards = [...tempUnseenCards];
                return newTypeCards;
            }
        }

        return newTypeCards;
    }

    function addInitialCards(cards: IFlashcardHistory[], numOfCards: number): void {
        // Adds first pass of hard, medium and easy cards
        for (let card of cards.slice(0, numOfCards)) {
            const kanji = kanjis.find(({ UID }) => UID === card.UID) as IKanjiData;
            tempKanjiData.push(kanji);
            tempFlashcardHistories.push(card);
        }
    }

    function addExtraCards(cards: IFlashcardHistory[]): void {
        const tempCards = [...cards];
        const tempKanjiData: IKanjiData[] = [];
        const tempFlashcardHistories: IFlashcardHistory[] = [];

        // tempKanjiData contains the flashcards added so far
        while (flashcards.length < NUM_CARDS && tempCards.length) {
            const flashcardHistory = tempCards.shift() as IFlashcardHistory;
            const kanjiData = kanjis.find(({ UID }) => UID === flashcardHistory?.UID);

            if (kanjiData) {
                tempKanjiData.push(kanjiData);
                tempFlashcardHistories.push(flashcardHistory);

                const convertibleCard: IConvertibleCard = {
                    ...kanjiData,
                    questionType: flashcardHistory.timesCorrect >= EASY_THRESHOLD && flashcardHistory.questionType === QUESTION_TYPE.kanji ?
                        QUESTION_TYPE.radicals :
                        flashcardHistory.questionType,
                };
                const tempTestKanjiData = convertToTestSet([convertibleCard], testChoices, isCustomSet, isFlashcardSet);
                flashcards.push({
                    testKanjiData: {
                        ...tempTestKanjiData[0],
                        cardType: `quiz ${tempTestKanjiData[0].questionType}`,
                    },
                    flashcardHistory,
                });
            }
        }
    }

    ////////////////////////////////////////////////////////////////////
    // DIVIDES KANJIS INTO PREVIOUSLY SEEN AND UNSEEN /////
    ////////////////////////////////////////////////////////////////////

    // KANJI DATA FOR ALL NEW FLASHCARDS FOR THIS ROUND
    // CAUTION! Kanji Ids begin at 1 but arrays at 0
    kanjis.slice(highestSeenKanjiId, highestSeenKanjiId + NUM_NEW_KANJI).forEach(kanji => {
        newKanjiData.push(kanji);
    });

    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////





    ////////////////////////////////////////////////////////////////////
    // FIRST PASS /////
    ////////////////////////////////////////////////////////////////////

    // ADD FIRST PASS OF HARD CARDS
    addInitialCards(hardCards, numHardCards);

    // ADD FIRST PASS OF MEDIUM CARDS
    addInitialCards(mediumCards, numMediumCards);

    // ADD FIRST PASS OF EASY CARDS
    addInitialCards(easyCards, numEasyCards);


    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////





    ////////////////////////////////////////////////////////////////////
    // CONVERTS SELECTED PREVIOUSLY SEEN CARDS INTO TEST CARDS /////
    ////////////////////////////////////////////////////////////////////

    const testChoices: ITestChoices = {
        isMeaningTest: true,
        isKanjiTest: true,
        isRadicalsTest: true,
        isOnyomiTest: true,
        isKunyomiTest: true,
    };
    const isCustomSet = true;
    const isFlashcardSet = true;

    let tempTestKanjiData: ITestKanjiData[] = [];

    if (tempKanjiData.length) {
        const tempConvertibleCards: IConvertibleCard[] = tempKanjiData.map((kanjiData, index) => ({
            ...kanjiData,
            questionType: tempFlashcardHistories[index].timesCorrect >= EASY_THRESHOLD && tempFlashcardHistories[index].questionType === QUESTION_TYPE.kanji ?
                QUESTION_TYPE.radicals :
                tempFlashcardHistories[index].questionType,
        }));
        tempTestKanjiData = convertToTestSet(tempConvertibleCards, testChoices, isCustomSet, isFlashcardSet);
    }

    // Adds all hard, medium and easy cards in full flashcard format
    let tempFlashcards: IFlashcard[] = [];
    for (let i = 0; i < tempTestKanjiData.length; i++) {
        tempFlashcards.push({
            testKanjiData: {
                ...tempTestKanjiData[i],
                cardType: `quiz ${tempFlashcardHistories[i].questionType}`,
            },
            flashcardHistory: tempFlashcardHistories[i],
        });
    }

    shuffle<IFlashcard>(tempFlashcards);

    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////





    ////////////////////////////////////////////////////////////////////
    // GENERATE NEW UNSEEN FLASHCARDS - First with study card and then with meanings questions
    ////////////////////////////////////////////////////////////////////

    const newConvertibleCards: IConvertibleCard[] = newKanjiData.map(kanjiData => ({
        ...kanjiData,
        questionType: QUESTION_TYPE.meaning,
    }));
    const newTestKanjiData = convertToTestSet(newConvertibleCards, testChoices, isCustomSet, isFlashcardSet);
    const newFlashcards: IFlashcard[] = [];

    for (let kanji of newTestKanjiData) {
        newFlashcards.push({
            testKanjiData: {
                ...kanji,
                cardType: `new ${kanji.questionType}`,
            },
            flashcardHistory: {
                UID: kanji.UID,
                timesSeen: 0,
                timesCorrect: 0,
                questionType: QUESTION_TYPE.study,
                value: kanji.meanings[0],
                score: 0,
            }
        });

        kanji.meanings.forEach(meaning => {
            newFlashcards.push({
                testKanjiData: {
                    ...kanji,
                    cardType: `quiz ${kanji.questionType}`,
                },
                flashcardHistory: {
                    UID: kanji.UID,
                    timesSeen: 0,
                    timesCorrect: 0,
                    questionType: QUESTION_TYPE.meaning,
                    value: meaning,
                    score: 0,
                }
            });

            // removes new cards from unseen cards
            unseenCards.every((card, index) => {
                if (card.UID === kanji.UID &&
                    card.questionType === QUESTION_TYPE.meaning &&
                    card.value === meaning
                ) {
                    currUnseenCards = [...currUnseenCards.slice(0, index), ...currUnseenCards.slice(index + 1)];
                    return false;
                }

                return true;
            });
        });
    }

    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////





    ////////////////////////////////////////////////////////////////////
    // ADD NEW UNSEEN FLASHCARDS /////
    ////////////////////////////////////////////////////////////////////

    let flashcards: IFlashcard[] = [];
    const spacing = Math.floor(tempFlashcards.length / (newFlashcards.length + 1));

    // If exisitng flashcard record
    // Else the flashcards are just the new fashcards because theuser just initialized flashcards for first time
    if (tempFlashcards.length) {

        // if soacing = 0, guranttes a minium of 1 for insertion points
        const insertionPoint = spacing || spacing + 1

        for (let newFlashcard of newFlashcards) {
            for (let i = 0; i < insertionPoint; i++) {
                const tempFlashcard = tempFlashcards.shift() as IFlashcard;
                if (tempFlashcard) {
                    flashcards.push(tempFlashcard);
                }
            }
            flashcards.push(newFlashcard);
        }

        // remaining tempFlashcards not added yet
        flashcards = [...flashcards, ...tempFlashcards];
    } else {
        flashcards = [...newFlashcards];
    }

    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////


    ////////////////////////////////////////////////////////////////////
    // SECOND PASS /////
    ////////////////////////////////////////////////////////////////////

    // ADD EXTRA HARD CARDS IF FLASHCARDS NOT A FULL SET YET
    // addExtraCards(hardCards, numHardCards);
    addExtraCards(hardCards);

    // ADD EXTRA MEDIUM CARDS IF FLASHCARDS NOT A FULL SET YET
    // addExtraCards(mediumCards, numMediumCards);
    addExtraCards(mediumCards);

    // ADD EXTRA EASY CARDS IF FLASHCARDS NOT A FULL SET YET
    // addExtraCards(easyCards, numEasyCards);
    addExtraCards(easyCards);

    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////



    ////////////////////////////////////////////////////////////////////
    // ADD EXTRA FLASHCARDS FOR FIRST FEW SESSIONS /////
    ////////////////////////////////////////////////////////////////////

    // This will add about double the amount of cards if less than 100 cards. But it won't go past 100.
    if (highestSeenKanjiId < NUM_CARDS) {
        let c = 0;
        const maxCards = Math.min(NUM_CARDS, highestSeenKanjiId + (3 * NUM_NEW_KANJI));
        let tempExtraCards: IFlashcard[] = [];

        if (newFlashcards.length) {
            while (flashcards.length < maxCards) {
                const validIndex = c % newFlashcards.length;
                const card = newFlashcards[validIndex];

                if (card.flashcardHistory.questionType !== QUESTION_TYPE.study) {
                    tempExtraCards.push(card);
                }
                c++;

                // once entire newFlashcards have beencycled through, tempExtracards is shuffled,
                // scrubbed for same cards being adjacent, added to flashcard and reset to empty array 
                if (c === newFlashcards.length) {
                    shuffle<IFlashcard>(tempExtraCards);
                    const lastCard = flashcards[flashcards.length - 1];
                    const firstCard = tempExtraCards[0];
                    const firstCardIdx = flashcards.length;
                    flashcards.push(...tempExtraCards);

                    // swaps first and ssecond cards
                    // prevents same cards from being next to each other by swapping first new card with second one
                    if (lastCard.flashcardHistory.UID === firstCard.flashcardHistory.UID &&
                        lastCard.flashcardHistory.questionType === firstCard.flashcardHistory.questionType &&
                        lastCard.flashcardHistory.value === firstCard.flashcardHistory.value
                    ) {
                        const midPoint = Math.floor(firstCardIdx / 2);
                        flashcards[firstCardIdx] = flashcards[midPoint];
                        flashcards[midPoint] = firstCard;
                    }

                    tempExtraCards = [];
                    c = 0;
                }
            }
        }

        // Does this again because last tempExtraCards aded may not be a full cycle through newFlashcards
        // tempExtracards is shuffled, scrubbed for same cards being adjacent, added to flashcard and reset to empty array 
        if (tempExtraCards.length) {
            shuffle<IFlashcard>(tempExtraCards);
            const lastCard = flashcards[flashcards.length - 1];
            const firstCard = tempExtraCards[0];
            const firstCardIdx = flashcards.length;
            flashcards.push(...tempExtraCards);

            // swaps first and ssecond cards
            // prevents same cards from being next to each other by swapping first new card with second one
            if (tempExtraCards[1] &&
                lastCard.flashcardHistory.UID === firstCard.flashcardHistory.UID &&
                lastCard.flashcardHistory.questionType === firstCard.flashcardHistory.questionType &&
                lastCard.flashcardHistory.value === firstCard.flashcardHistory.value
            ) {
                const midPoint = Math.floor(firstCardIdx / 2);
                flashcards[firstCardIdx] = flashcards[midPoint];
                flashcards[firstCardIdx + 1] = firstCard;
            }
        }
    }

    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////





    ////////////////////////////////////////////////////////////////////
    // ADDS NEW QUESTION TYPES OF ALREADY SEEN KANJI TO HARD CARDS FOR FUTURE SESSIONS
    ////////////////////////////////////////////////////////////////////

    // Adds different question type cards of cards seen
    for (let card of mediumCards) {
        if (card.timesCorrect >= 5 || card.timesSeen >= 12) {
            const newTypeCards = newTypes(card);
            hardCards.push(...newTypeCards);
        }
    }

    for (let card of easyCards) {
        const newTypeCards = newTypes(card);
        hardCards.push(...newTypeCards);
    }


    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////





    // console.log({
    //     flashcards,
    //     highestSeenKanjiId,
    //     newKanjiData,
    //     currUnseenCards,
    //     unseenCards,
    //     hardCards,
    //     mediumCards,
    //     easyCards,
    // });
    // console.log('====================================================================================\n');
    // console.log('====================================================================================\n');
    // console.log('====================================================================================\n');
    // console.log('========================== >>>>>>>>>> END OF SET <<<<<<<<<< ========================\n');
    // console.log('====================================================================================\n');
    // console.log('====================================================================================\n');
    // console.log('====================================================================================\n');

    // SAVE USER DATA IN DB
    const currFlashcardRecord = {
        ...flashcardRecord,
        highestSeenKanjiId: highestSeenKanjiId + NUM_NEW_KANJI,
        unseenCards: currUnseenCards,
    };

    setUserFlashcardRecord(currFlashcardRecord);

    return flashcards;
}

function getChallengeFlashcards(flashcard: IFlashcard): IFlashcard[] {
    const challengeFlashcards: IFlashcard[] = [];
    const flashcardHistories: IFlashcardHistory[] = [];
    const kanjiData = kanjis.find(({ UID }) => UID === flashcard.flashcardHistory.UID) as IKanjiData;
    createFlashcardHistories(kanjiData, flashcardHistories);

    const testChoices: ITestChoices = {
        isMeaningTest: true,
        isKanjiTest: true,
        isRadicalsTest: true,
        isOnyomiTest: true,
        isKunyomiTest: true,
    };

    const isCustomSet = true;
    const isFlashcardSet = true;

    const convertibleCards: IConvertibleCard[] = flashcardHistories.map(flashcardHistory => ({
        ...kanjiData,
        // converts all kanji questions into radical questions because radical quesons are harder
        //and a kanji quetsion would be redundant
        questionType: flashcardHistory.questionType === QUESTION_TYPE.kanji ?
            QUESTION_TYPE.radicals :
            flashcardHistory.questionType,
    }));

    const testSet = convertToTestSet(convertibleCards, testChoices, isCustomSet, isFlashcardSet);

    // creates set of challenge flashcards
    testSet.forEach((testKanjiData, index) => {
        challengeFlashcards.push({
            testKanjiData: {
                ...testKanjiData,
                cardType: `quiz ${testKanjiData.questionType}`,
            },
            flashcardHistory: flashcardHistories[index],
        });
    });

    return challengeFlashcards;
}

function updateFlashcardRecordData(): void {
    const flashcardRecord = getUserFlashcardRecord();
    const {
        unseenCards,
        challengedCards,
        hardCards,
        mediumCards,
        easyCards,
    } = flashcardRecord;

    const newUnseenCards: IFlashcardHistory[] = [];
    const newChallengedCards: IFlashcardHistory[] = [];
    const newHardCards: IFlashcardHistory[] = [];
    const newMediumCards: IFlashcardHistory[] = [];
    const newEasyCards: IFlashcardHistory[] = [];

    function updatedCard(card: IFlashcardHistory, kanji: IKanjiData): IFlashcardHistory | undefined {
        let newCard: IFlashcardHistory = { ...card };

        switch (card.questionType) {
            case QUESTION_TYPE.kanji:
                /* The kanji value is replaced in all cases because UIDS and kanjis are permantlly associated with eachother, but
                    there is alwaysa possiblily that a Unicode kanji may be replaced with a custom SVG because the Japanese font doesn't support that character.
                    Because of the permanent association betwwen a kanji and a UID, there is no risk of replacing the value with the wrong custom SVG kanji.
                */
                newCard = {
                    ...newCard,
                    value: kanji.kanji,
                }
                break;
            case QUESTION_TYPE.meaning:
                if (!kanji.meanings.includes(card.value)) {
                    return undefined;
                }
                break;
            case QUESTION_TYPE.onyomi:
                if (!kanji.onyomi.includes(card.value)) {
                    return undefined;
                }
                break;
            case QUESTION_TYPE.kunyomi:
                if (!kanji.kunyomi.includes(card.value)) {
                    return undefined;
                }
                break;
        }

        return newCard;
    }

    function scrubFlashcardHistories(cards: IFlashcardHistory[], newCards: IFlashcardHistory[]): void {
        // checks if each card is in the latest kanji data set and if not, it removes it.
        cards.forEach(card => {
            const kanji = kanjis.find(({ UID }) => UID === card.UID);

            if (kanji) {
                const newCard = updatedCard(card, kanji);

                if (newCard) {
                    newCards.push(newCard);
                }
            }
        });
    }

    // checks if each card is in the latest kanji data set and if not, it removes it.
    scrubFlashcardHistories(unseenCards, newUnseenCards);
    scrubFlashcardHistories(challengedCards, newChallengedCards);
    scrubFlashcardHistories(hardCards, newHardCards);
    scrubFlashcardHistories(mediumCards, newMediumCards);
    scrubFlashcardHistories(easyCards, newEasyCards);

    const allCards = [
        ...newUnseenCards,
        ...newChallengedCards,
        ...newHardCards,
        ...newMediumCards,
        ...newEasyCards,
    ];
    const allCardUIDS: string[] = allCards.map(({ UID }) => UID);

    // Adds new card values for the latest kanji data set
    for (const kanji of kanjis) {
        
        if (!allCardUIDS.includes(kanji.UID)) {
            // If it is a totally new kanji in the data set
            // add kanji card
            newUnseenCards.push({
                UID: kanji.UID,
                timesSeen: 0,
                timesCorrect: 0,
                questionType: QUESTION_TYPE.kanji,
                value: kanji.kanji,
                score: 0,
            });

            // add meaning cards
            const newMeanings = kanji.meanings.map(meaning => ({
                UID: kanji.UID,
                timesSeen: 0,
                timesCorrect: 0,
                questionType: QUESTION_TYPE.meaning,
                value: meaning,
                score: 0,
            }));
            newUnseenCards.push(...newMeanings);

            // add onyomi cards
            const newOnyomi = kanji.onyomi.map(onyomi => ({
                UID: kanji.UID,
                timesSeen: 0,
                timesCorrect: 0,
                questionType: QUESTION_TYPE.onyomi,
                value: onyomi,
                score: 0,
            }));
            newUnseenCards.push(...newOnyomi);

            // add kunyomi cards
            const newKunyomi = kanji.kunyomi.map(kunyomi => ({
                UID: kanji.UID,
                timesSeen: 0,
                timesCorrect: 0,
                questionType: QUESTION_TYPE.kunyomi,
                value: kunyomi,
                score: 0,
            }));
            newUnseenCards.push(...newKunyomi);
        } else {
            // adds means new or updated meanings
            for (const meaning of kanji.meanings) {
                let hasValue = false;
                innerLoop:
                    for (const card of allCards) {
                        if (card.UID === kanji.UID &&
                            card.questionType === QUESTION_TYPE.meaning &&
                            card.value === meaning
                        ) { 
                            hasValue = true;
                            break innerLoop;
                        }
                    }

                if (!hasValue) {
                    unseenCards.push({
                        UID: kanji.UID,
                        timesSeen: 0,
                        timesCorrect: 0,
                        questionType: QUESTION_TYPE.meaning,
                        value: meaning,
                        score: 0,
                    });
                }
            }

            // adds means new or updated onyomi
            for (const onyomi of kanji.onyomi) {
                let hasValue = false;
                innerLoop:
                    for (const card of allCards) {
                        if (card.UID === kanji.UID &&
                            card.questionType === QUESTION_TYPE.onyomi &&
                            card.value === onyomi
                        ) { 
                            hasValue = true;
                            break innerLoop;
                        }
                    }

                if (!hasValue) {
                    unseenCards.push({
                        UID: kanji.UID,
                        timesSeen: 0,
                        timesCorrect: 0,
                        questionType: QUESTION_TYPE.onyomi,
                        value: onyomi,
                        score: 0,
                    });
                }
            }

            // adds means new or updated kunyomi
            for (const kunyomi of kanji.kunyomi) {
                let hasValue = false;
                innerLoop:
                    for (const card of allCards) {
                        if (card.UID === kanji.UID &&
                            card.questionType === QUESTION_TYPE.kunyomi &&
                            card.value === kunyomi
                        ) { 
                            hasValue = true;
                            break innerLoop;
                        }
                    }

                if (!hasValue) {
                    unseenCards.push({
                        UID: kanji.UID,
                        timesSeen: 0,
                        timesCorrect: 0,
                        questionType: QUESTION_TYPE.kunyomi,
                        value: kunyomi,
                        score: 0,
                    });
                }
            }
        }
    }

    setUserFlashcardRecord({
        ...flashcardRecord,
        unseenCards: newUnseenCards,
        challengedCards: newChallengedCards,
        hardCards: newHardCards,
        mediumCards: newMediumCards,
        easyCards: newEasyCards,
    });
}

function setHighestSeenKanji(kanjiId: number): void {
    localStorage.setItem('flashcards', '');

    const flashcardRecord = getUserFlashcardRecord();

    const {
        unseenCards,
        hardCards,
        mediumCards,
        easyCards,
    } = flashcardRecord as IFlashcardRecord;

    const goodUIDs = kanjis.slice(kanjiId).map(({ UID }) => UID);

    function removeCardFromPile(oldCards: IFlashcardHistory[]): IFlashcardHistory[] {
        // removes a flashcard from a difficulty set if it is for a sucessfully challenged kanji
        const tempNewCards: IFlashcardHistory[] = [];

        oldCards.forEach(card => {
            if (goodUIDs.includes(card.UID)) {
                tempNewCards.push(card);
            }
        });

        return tempNewCards;
    }

    // hardCards
    let newUnseenCards = removeCardFromPile(unseenCards);

    // hardCards
    let newHardCards = removeCardFromPile(hardCards);

    // mediumCards
    let newMediumCards = removeCardFromPile(mediumCards);

    // easyCards
    let newEasyCards = removeCardFromPile(easyCards);


    setUserFlashcardRecord({
        ...flashcardRecord,
        highestSeenKanjiId: kanjiId,
        unseenCards: newUnseenCards,
        hardCards: newHardCards,
        mediumCards: newMediumCards,
        easyCards: newEasyCards,
    });
}

const flashcardEngine = {
    getUserData,
    setUserData,
    getUserFlashcardRecord,
    setUserFlashcardRecord,
    makeFlashcards,
    getChallengeFlashcards,
    updateFlashcardRecordData,
    setHighestSeenKanji,
};

export default flashcardEngine;

// (function() {
//     const UIDS: string[] = [];
//     kanjis.forEach(kanji => {
//         let isValidUid = false;

//         while (!isValidUid) {
//             const alphaNum = Math.random().toString(36).slice(2, 6).toUpperCase();
//             const uid = `${kanji.Type?.slice(0, 1)}-${alphaNum}`;

//             if (alphaNum.length !== 4) {
//                 console.log({ alphaNum, uid });
//             }

//             if (alphaNum.length === 4 && !UIDS.includes(uid)) {
//                 UIDS.push(uid);
//                 isValidUid = true;
//             }
//         }
//     });

//     console.log(UIDS.length);
//     console.log({ UIDS: UIDS.join() });
// }
// )();

// (function() {
//     const flashcardRecord = getUserFlashcardRecord();

//     let {
//         unseenCards,
//         hardCards,
//         mediumCards,
//         easyCards,
//     } = flashcardRecord;

//     let newUnseenCards: IFlashcardHistory[] = [];
//     unseenCards.forEach(card => {
//         if (card.questionType as string === 'meanings') {
//             newUnseenCards.push({
//                 ...card,
//                 questionType: QUESTION_TYPE.meaning,
//             });
//         } else {
//             newUnseenCards.push(card);
//         }
//     });

//     let newHardCards: IFlashcardHistory[] = [];
//     hardCards.forEach(card => {
//         if (card.questionType as string === 'meanings') {
//             newHardCards.push({
//                 ...card,
//                 questionType: QUESTION_TYPE.meaning,
//             });
//         } else {
//             newHardCards.push(card);
//         }
//     });

//     let newMediumCards: IFlashcardHistory[] = [];
//     mediumCards.forEach(card => {
//         if (card.questionType as string === 'meanings') {
//             newMediumCards.push({
//                 ...card,
//                 questionType: QUESTION_TYPE.meaning,
//             });
//         } else {
//             newMediumCards.push(card);
//         }
//     });

//     let newEasyCards: IFlashcardHistory[] = [];
//     easyCards.forEach(card => {
//         if (card.questionType as string === 'meanings') {
//             newEasyCards.push({
//                 ...card,
//                 questionType: QUESTION_TYPE.meaning,
//             });
//         } else {
//             newEasyCards.push(card);
//         }
//     });

//      // SAVE USER DATA IN DB
//      const newFlashcardRecord = {
//         ...flashcardRecord,
//         unseenCards: newUnseenCards,
//         hardCards: newHardCards,
//         mediumCards: newMediumCards,
//         easyCards: newEasyCards,
//     };

//     setUserFlashcardRecord(newFlashcardRecord);
// })();