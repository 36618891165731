import { IFlashcard, IKanjiData, ITestKanjiData, QuestionType } from 'interfaces/interfaces';

export const DATABASE_URL = 'https://kanjiremastered-backend.herokuapp.com';
// export const DATABASE_URL = 'http://127.0.0.1:5000';
// export const DATABASE_URL = process.env.NODE_ENV === 'development'
//     ? process.env.REACT_APP_DB_URL_DEVELOPMENT
//     : process.env.REACT_APP_DB_URL_PRODUCTION;
export const DATA_SOURCE = {
    SEARCH: 'searchData',
    SUGGESTIONS: 'suggestions',
};
export const NO_DATA = 'NO_DATA';
export const RECENT_ITEM = 'RECENT_ITEM';
export const SEARCH_SERVICES_TERM = 'SEARCH_SERVICES_TERM';
export const KANJI_SEARCH_PATH = '/language/kanjisearch';
export const CACHE_DELAY = 20000;
export const SET_NAME_TAKEN = 'is already in use. Please choose a different name.';
// This review set name prefix is used to prevent custom set name collisions.
// It's very unlikely a user would name a custom set this.
export const REVIEW_KANJI_SET = 'REVIEW_KANJI_SET_0000000000_';
export const STANDARD_TEST_SET = '_STANDARD_TEST_SET_XXXXX';
export const CUSTOM_TEST_SET = '_CUSTOM_TEST_SET_XXXXX';
export const DUMMY_CARD_NUMBER = 11;
export const HIGHLIGHT_DELIMITER = '#';
export const KANJI_DATA_TYPES = {
    kanji: 'Kanji',
    radical: 'Radical',
    pseudoRadical: 'Pseudoradical',
    protoRadical: 'Proto-radical'
};

export const FLASHCARD_TYPE = {
    studyInstructions: 'study instructions',
    studyReview: 'study review',
    testInstructions: 'test instructions',
    testReview: 'test review',
    advertising: 'advertising',
    donate: 'donate',
    kanji: 'kanji',
    radical: 'kadical',
    pseudoRadical: 'pseudoradical',
    protoRadical: 'Pproto-radical',
    flashcardsInstructions: 'flashcards instructions',
    flashcardsReview: 'flashcards review',
    flashcardsFinished: 'flashcards finished',
    challengeSucess: 'challenge success',
    challengeFailure: 'challenge failure',
    quizMeaning: 'quiz meaning',
    quizKanji: 'quiz kanji',
    quizRadicals: 'quiz radicals',
    quizOnyomi: 'quiz onyomi',
    quizKunyomi: 'quiz kunyomi',
    newMeaning: 'new meaning',
    newKanji: 'new kanji',
    newOnyomi: 'new onyomi',
    newKunyomi: 'new kunyomi',
    newRadical: 'new radical',
    newPseudoRadical: 'new pseudoradical',
    newProtoRadical: 'new proto-radical',
};

export const NUM_KANJIS = 2500;
export const NO_READING = '-none-';
export const CHALLENGE_THRESHOLD = 12;
export const EASY_THRESHOLD = 12;
export const NUM_RADICAL_CHOICES = 15;

export const QUESTION_TYPE = {
    study: 'study' as QuestionType,
    meaning: 'meaning' as QuestionType,
    kanji: 'kanji' as QuestionType,
    radicals: 'radicals' as QuestionType,
    onyomi: 'onyomi' as QuestionType,
    kunyomi: 'kunyomi' as QuestionType,
};

export const dummyStudyFlashcard: IKanjiData = {
    id: 0,
    UID: '',
    kanji: '',
    type: '',
    strokes: 0,
    meanings: [''],
    bushu: [''],
    radicals: [''],
    onyomi: [],
    kunyomi: [],
    mnemonic: '',
    notes: '',
    lookalikes: [],
    similarMeanings: [],
    onSoundalikes: [],
    kunSoundalikes: [],
};

export const dummyTestFlashcard: ITestKanjiData = {
    id: 0,
    UID: '',
    kanji: '',
    type: '',
    strokes: 0,
    meanings: [''],
    bushu: [''],
    radicals: [''],
    onyomi: [],
    kunyomi: [],
    mnemonic: '',
    notes: '',
    lookalikes: [],
    similarMeanings: [],
    onSoundalikes: [],
    kunSoundalikes: [],
    meaning: '',
    answerChoices: ['', '', '', ''],
    correctAnswers: [],
    questionType: QUESTION_TYPE.study,
};

export const dummyFlashcard: IFlashcard = {
    testKanjiData: dummyTestFlashcard,
    flashcardHistory: {
        UID: '',
        timesSeen: 0,
        timesCorrect: 0,
        questionType: QUESTION_TYPE.study,
        value: '',
        score: 0,
    }
}

export const RESPONSE_MESSAGE = {
    setSaved: 'Set saved!',
    nameTaken: 'This set name already exists. Please choose another one.',
};

export const COLOR = {
    blackColor: '#050520',
    blueColor: '#212435',
    brightColor: '#80D0F0',
    borderColor: '#C0C0C0',
    charcoalColor: '#292D3D',
    contrastColor: '#AABBCF',
    deactivatedColor: '#393E58',
    mediumGrayColor: '#BBBBBB',
    mediumLightGrayColor: '#DDDDDD',
    lightGrayColor: '#F5F5F5',
    redColor: '#C80000',
    mutedRedColor: '#',
    whiteColor: '#FFFFFF',
    lightBgColor: '#FAFAFA',
    mediumBgColor: '#303030',
    darkBgColor: '#151515',
    meaningColor: '#20CFFFCA3232',
    radicalColor: '#5EF3A4',
    onyomiColor: '#FDF365',
    kunyomiColor: '#FF43AA',
}