import 'core-js';
import {
    // SEARCH_SERVICES_TERM,
    // DATABASE_URL,
    DATA_SOURCE,
    KANJI_DATA_TYPES,
    QUESTION_TYPE,
    NO_READING,
    NUM_RADICAL_CHOICES,
} from 'constants/constants';
// import { getUpdatedOrCachedData } from 'helpers/cachingHelper';
import kanjisJSON from './kanjidata.json';
import {
    // IFetchDataProps,
    ISearchKanjiData,
    ISearchResults,
    IBaseKanjiData,
    ITestChoices,
    IKanjiData,
    IConvertibleCard,
    ITestKanjiData,
    IFlashcard,
    QuestionType,
    ICachedFlashcards,
} from 'interfaces/interfaces';
import { shuffle } from 'helpers/helpers';
import flashcardEngine from './flashcardEngine';

/*
  These search services provides all the functions for the search features
  in Kanji Search and the Flashcards app.
  This is an intermediate between the UI and the Kanji JSON data.
*/

interface IDataConstants {
    [key: string]: number;
}

export const KANJI_DATA_CONSTANTS: IDataConstants = {
    ID: 0,
    UID: 1,
    KANJI: 2,
    TYPE: 3,
    STROKES: 4,
    MEANINGS: 5,
    BUSHU: 6,
    RADICALS: 7,
    ONYOMI: 8,
    KUNYOMI: 9,
    MNEMONIC: 10,
    NOTES: 11,
    LOOKALIKES: 12,
    SIMILARMEANINGS: 13,
    ONSOUNDALIKES: 14,
    KUNSOUNDALIKES: 15,
};

export const SEARCH_DATA_CONSTANTS: IDataConstants = {
    SD_ID: 0,
    SD_KANJI: 1,
    SD_MEANINGS: 2,
    SD_HEADING: 3,
    SD_ONYOMI: 5,
    SD_KUNYOMI: 6
};

const {
    ID,
    UID,
    KANJI,
    TYPE,
    STROKES,
    MEANINGS,
    BUSHU,
    RADICALS,
    ONYOMI,
    KUNYOMI,
    MNEMONIC,
    NOTES,
    LOOKALIKES,
    SIMILARMEANINGS,
    ONSOUNDALIKES,
    KUNSOUNDALIKES,
} = KANJI_DATA_CONSTANTS;

const ONYOMI_HEADING = 'Onyomi';
const KUNYOMI_HEADING = 'Kunyomi';
/* 'searchTerm' variable or parameter is passed by a search function call
    'kanjis' object is the kanji JSON data
    'kanji' variable or parameter refers to a key/value from kanji data JSON
    'result' variable or parameter refers to a value returned by a search function
*/


// FLASK ROUTES AND DATA RETRIEVAL FROM BACKEND //
// const KANJI_ROUTE = '/api/kanjidata';
// const STANDARD_KANJI_SET_ROUTE = '/api/standardkanjiset';
// const CUSTOM_KANJI_SET_ROUTE = '/api/customkanjiset';

// ALL KANJIS, FUNCTIONS AS "DATABASE" IN FRONTEND
const kanjis: IKanjiData[] = kanjisJSON.map(kanji => ({
    id: kanji[ID] as number,
    UID: kanji[UID] as string,
    kanji: kanji[KANJI] as string,
    type: kanji[TYPE] as string,
    strokes: kanji[STROKES] as number,
    meanings: kanji[MEANINGS] as string[],
    bushu: kanji[BUSHU] as string[],
    radicals: kanji[RADICALS] as string[],
    onyomi: kanji[ONYOMI] as string[],
    kunyomi: kanji[KUNYOMI] as string[],
    mnemonic: kanji[MNEMONIC] as string,
    notes: kanji[NOTES] as string,
    lookalikes: kanji[LOOKALIKES] as string[],
    similarMeanings: kanji[SIMILARMEANINGS] as string[],
    onSoundalikes: kanji[ONSOUNDALIKES] as string[],
    kunSoundalikes: kanji[KUNSOUNDALIKES] as string[],
}));

// function searchServicesRoute<T>({ route, searchTerm = '', method = 'GET' }: IFetchDataProps): Promise<T> {
//     const routeWithSearchTerm = `${route}/${searchTerm}`;
//     const url = `${DATABASE_URL}${searchTerm ? routeWithSearchTerm : route}`;
//     const configs: RequestInit = {
//         method,
//         mode: 'cors',
//         headers: {
//             'Content-Type': 'application/json'
//         },
//     };

//     return fetch(url, configs)
//         .then(response => {
//             if (!response.ok) {
//               throw new Error(response.statusText)
//             }
//             return response.json() as Promise<T>;
//         })
//         .then(data => data);
// }

// HELPER FUNCTIONS
function updateTempResult(kanji: IBaseKanjiData, headingOrColumn: string | number): ISearchKanjiData {
    /*  DESCRIPTION
        Pushes data into a temp array that is an intermediate search result

        PARAMETERS kanji: array, headingOrcolumn: string or integer

        RETURNS tempResult: array

        DATA STRUCTURE of tempResult:
        An array of jagged arrays
            [Id #, Kanji, [...meanings], heading/depth, [...radicals], [...onyomi], [...kunyomi]]
        Example:
            [43, '由', ['reason','',''], 3, ['bar','field','',''], ['YUU'], ['yoshi]]
    */

    return {
        ...kanji,
        heading: headingOrColumn, // or depth or height
    };
}

function addPeriodsTo(searchTerm: string): string[] {
    /*  DESCRIPTION
        Generates all possible splices of search term with "." except edge cases.

        PARAMETERS searchTerm: string
        RETURNS punctuated: array
        DATA STRUCTURE of punctuated:
            ["abcd", "a.bcd", "ab.cd", "abc.d"]
            Output includes original search term but not ".abcd" or "abcd."
    */
    let period: number;
    let term = searchTerm;
    while (term.includes('.')) {
        period = searchTerm.indexOf('.');
        term = `${term.slice(0, period)}${term.slice(period + 1)}`;
    }

    const punctuated: string[] = [term];
    for (let i = 1; i < term.length; i++) {
        punctuated.push(`${term.substring(0,i)}.${term.substring(i)}`);
    }

    return punctuated;
}

function findKunyomisWithPeriod(punctuated: string[], kanji: IBaseKanjiData, dataSource: string): ISearchKanjiData[] {
    /*  DESCRIPTION
        Secondary search feature. Searches for kunyomi with period "." 
        between each letter such as "hito.tsu", because Kunyomi Reading1 
        and Kunyomi Reading2 columns sometimes have a "." in the entry 
        string in an unpredictable place. Therefore, checking all possible 
        interior occurences of "." in string is necessary to get result.
    */
    const results: ISearchKanjiData[] = [];
    punctuated.forEach(kunyomi => {
        (kanji.kunyomi).forEach(kunyomiReading => {
            // checks for exact match if it's for searchData OR checks for partial match if it's for suggestions
            if ((dataSource === DATA_SOURCE.SEARCH && kunyomi === kunyomiReading.trim().toLowerCase()) ||
                (dataSource === DATA_SOURCE.SUGGESTIONS && kunyomiReading.toLowerCase().includes(kunyomi))) {
                const tempResult: ISearchKanjiData = updateTempResult(kanji, KUNYOMI_HEADING);
                tempResult.match = kunyomiReading; // partially matched reading
                tempResult.priority = 4; // suggestion priority
                results.push(tempResult);
            }
        })
    });

    return results;
}

// function findParentKanji(searchData) {
// /*  DESCRIPTION
//       Secondary search feature. It returns list of all ancestors 
//       of the search term broken down by ancestral height level.

//     PARAMETERS searchData: object

//     DATA STRUCTURE of searchData:
//       An object to prevent duplicates when new entries are added
//       key: integer : value: array
//         [
//             [Id #, Kanji, [...meanings], heading/depth, [...radicals]], 
//             [...], 
//             ...
//         ]
//         Example:
//         [
//             [43, '由', ['reason','',''], 3, ['bar','field','','']], 
//             [...], 
//             ...
//         ]

//     RETURNS parentsData: object

//     DATA STRUCTURE of parentsData:
//         [
//             [Id #, Kanji, [...meanings], heading/depth, [...radicals]], 
//             [...], 
//             ...
//         ]
//         Example:
//         [
//             [43, '由', ['reason','',''], ['bar','field','',''], 3], 
//             [...], 
//             ...
//         ]
// */
//     let parentsData = {},
//         searchDataCopy = {},
//         c = 1,
//         height = 0;
//         /* 'height' variable provides ancestry level. Initialize at 0 
//             because the kanji from search_term is at the zeroeth. 
//             From it, while loop below will search for its descendants
//             For example: 国 0 > 玉 1 > 王 2 > 三 3 > 一 4 etc. */
        
//     /*  Search data is converted to object to prevent duplicate entries 
//         since keys update a value rather than duplicate it */
//     searchData.forEach(result => { searchDataCopy[result.Id] = result });
//     console.log({ searchDataCopy });

//     while (c > 0) {
//         let tempSearchData = {};
//         height++;
//         c = 0;

//         /* 1st, it goes through all the existing search results object */
//         for (const result in searchDataCopy) {
//             /* 2nd, For each result, gets radicals which will be basis to
//                 find parents in the meanings of every kanji in kanji data */
//             searchDataCopy[result].Radicals.forEach(radical => {
//                 /* 3rd, if radical is empty string, maybe infinite while loop */
//                 if (radical !== '') {
//                     /* 4th, it goes through all the kanji data for each radical above */
//                     let searchFrom = searchDataCopy[result].Id;
//                     // console.log({ searchFrom, kanjis });
//                     for (let kanji = 0; kanji < kanjis.length; kanji++) {
//                         /* 5th, loops through meanings and compares each radical */
//                         for (let j = 0; j < kanjis[kanji][MEANINGS].length; j++) {
//                             /* 6th, If radical matches a meaning and not previously found... */
//                             if (radical === kanjis[kanji][MEANINGS][j]
//                                 && !Reflect.has(parentsData, kanjis[kanji][ID])) {
//                                 /* 7th, ...temp search data is given a new key & value */
//                                 let temp = updateTempResult(kanjis[kanji], height);
//                                 tempSearchData[temp.Id] = temp;
//                                 c++;
//                             }
//                         }
//                     }
//                 }
//             });
//         };
//         searchDataCopy = {...tempSearchData};
//         parentsData = {...parentsData, ...tempSearchData};
//     }
//     return Object.values(parentsData).length !== 0 
//             ? Object.values(parentsData).map( parent => parent.Meanings[0])
//             : [];
// }

function findParentKanji(radicals: string[]): string[] {
    const parentsData: string[] = [];

    radicals.forEach((radical: string) => {
        for (let i = 0; i < kanjis.length; i++) {
            const kanji: IBaseKanjiData = kanjis[i];

            if (kanji.meanings.includes(radical)) {
                parentsData.push(...kanji.radicals);
                break;
            }
        }
    });

    return parentsData;
}

function findDerivativeKanji(uniqueResults: ISearchResults): ISearchKanjiData[] {
/*  DESCRIPTION
      Secondary search feature. It returns list of all derivatives
      of the search term broken down by derivation depth level.

    PARAMETERS uniqueResults: object

    DATA STRUCTURE of uniqueResults:
      An object to prevent duplicates when new entries are added
      key: integer : value: array
        { 
            Id: [Id #, Kanji, [...meanings], heading/depth, [...radicals]], 
            Id: [...], 
            ...
        }
      Example:
        { 
            43: [43, '由', ['reason','',''], 3, ['bar','field','','']], 
            57: [...], 
            ...
        }

    RETURNS an array: SearchableKanji[]

    DATA STRUCTURE of searchData
      key: integer : value: array
        { 
            Id: [Id #, Kanji, [...meanings], heading/depth, [...radicals]], 
            Id: [...], 
            ...
        }
      Example:
        { 
            43: [43, '由', ['reason','',''], 3, ['bar','field','','']], 
            57: [...], 
            ...
        }
*/
    let searchData: ISearchResults = {};
    let searchDataCopy: ISearchResults = {...uniqueResults};
    let depth = 0;
    let c = 1;
    /*  'depth' variable provides derivation level. Initialize at 0 
        because the kanji from search_term is at the zeroeth. 
        From it, while loop below will search for its descendants
        For example: 一 0 > 三 1 > 王 2 > 玉 3 > 国 4 etc.
    */
    while (c > 0) {
        const tempSearchData: ISearchResults = {};
        depth++;
        c = 0;

        // 1st, it goes through all the existing unique search results
        for (const result in searchDataCopy) {

            // 2nd, For each result, gets meanings which will be basis to
            // find derivatives in the radicals of every kanji in kanji data
            for (let i = 0; i < searchDataCopy[result].meanings.length; i++) {
                const meaningToCheck = searchDataCopy[result].meanings[i];

                // 3rd, if radical is empty string, maybe infinite while loop
                if (meaningToCheck !== '') {

                    // 4th, it goes through all the kanji data for each meaning above
                    const searchFrom: number = searchDataCopy[result].id;

                    for (let kanji = searchFrom; kanji < kanjis.length; kanji++) {

                        // 5th, loops through meanings and compares each radical
                        for (let j = 0; j < kanjis[kanji].radicals.length; j++) {

                            // 6th, If meaning matches a radical...          AND not found already
                            if (meaningToCheck === kanjis[kanji].radicals[j] && !Reflect.has(searchData, kanjis[kanji].id)) {

                                // 7th, ...temp search data is given a new key & value
                                const temp: ISearchKanjiData = updateTempResult(kanjis[kanji], depth);
                                tempSearchData[temp.id] = temp;
                                c++;
                                // Uncomment below to bug test infinite loop
                                // console.log(searchDataCopy[result].Id);
                            }
                        }
                    }
                }
            }
        }

        searchDataCopy = { ...tempSearchData };
        searchData = { ...searchData, ...tempSearchData };
    }

    return Object.values(searchData);
}

function findIntersectionOf(previous: ISearchKanjiData[], current: ISearchKanjiData[], counter: number): ISearchKanjiData[] {
    /*  DESCRIPTION
        Finds intersection based on Id number i.e. kanjis[kanji][0][0]
        This prevents duplicate for with multiple derivation levels with
        respect to different radicals e.g.

        PARAMETERS previous: array, current: array, counter: integer

        RETURNS intersection: array

        DATA STRUCTURE of current, previous and intersection:
            the same as searchData array
    */
    if (counter === 0) {
        return current;
        /* Since searchData is initialized as an empty array [] and is passed as the
            first 'previous', the intersection with 'current' will always be empty.
            Thus, a counter keeps track of the first radical search. Only after
            counter is > 0 and searchData has been found from the first radical, can
            intersections be found. If not, it would perpetually return an empty array.
        */
    } else {
        const previousIds: number[] = previous.map(result => result.id);
        const currentIds: number[] = current.map(result => result.id);
        const intersection: ISearchKanjiData[] = [];

        currentIds.forEach((Id, index) => {
            const prevIdIndex: number = previousIds.indexOf(Id);
            const curr: ISearchKanjiData = current[index];

            // IF A PREVIOUS INDEX IS FOUND
            if (prevIdIndex !== -1) {
                previous[prevIdIndex].heading = Math.max(0, (previous[prevIdIndex].heading as number) - 1);
                curr.heading = Math.max(0, (curr.heading as number) - 1);
                // previous[prevIdIndex].Heading -= 1; // subtract 1 from the derivation level heading
                // curr.Heading -= 1;
                intersection.push(previous[prevIdIndex].heading as number < curr.heading ? previous[previousIds.indexOf(Id)] : curr);
            }
        });

        return intersection;
    }
}

function convertToTestSet(
    cards: IConvertibleCard[],
    testChoices: ITestChoices = {} as ITestChoices,
    isCustom: boolean,
    isFlashcardSet: boolean = false,
): ITestKanjiData[] {
    // const convertibleCards: IConvertibleCard[] = isFlashcardSet ?
    //     (flashcards as IFlashcard[]).map(flashcard => ({
    //         ...flashcard.testKanjiData,
    //         questionType: flashcard.flashcardHistory.questionType,
    //     })) :
    //     flashcards as IConvertibleCard[];

    const arrMeanings = isCustom
        ? ['three','king','ball','country','up','additionally','luck','round','universal','place'] : [];
    const arrKanji = isCustom
        ? ['三','王','玉','国','更','上','吉','丸','普','所'] : [];
    const arrOnyomi = isCustom
        ? ['CHI','FU','HOU','KAI','KOKU','KYUU','GYOKU','OU','SAN','SHO','SHI','TAI'] : [];
    const arrKunyomi = isCustom
        ? ['age.ru','bako', 'kuni','mi.ttsu','nage.ku','tama','tokoro','yoshi'] : [];
    const arrRadicals: string[] = [];

    const {
        isMeaningTest,
        isKanjiTest,
        isRadicalsTest,
        isOnyomiTest,
        isKunyomiTest,
    } = testChoices;

    const questionTypeChoices: QuestionType[] = [];

    function scrubData(arr: string[], property: QuestionType): void {
        // adds to array all memembers of a test set of the same type as the question type
        // example: if question type is 'Kanji', then all the kanjis in the test set will be added
        if (property === QUESTION_TYPE.meaning) {
            // because Radicals, Onyomi and Kunyomi properties are arrays of strings
            for (const card of cards) {
                arr.push(...card.meanings);
            }
        }

        if (property === QUESTION_TYPE.kanji) {
            // because Kanji property is a single string
            for (const card of cards) {
                arr.push(card.kanji);
            }
            
        }

        if (property === QUESTION_TYPE.radicals) {
            const allRadicals = kanjis.filter(({ type }) => type === 'Radical').map(({ kanji }) => kanji);
            arr.push(...allRadicals);
        }

        if (property === QUESTION_TYPE.onyomi) {
            // because Radicals, Onyomi and Kunyomi properties are arrays of strings
            for (const kanjiData of kanjis) {
                arr.push(...kanjiData.onyomi);
            }
        }

        if (property === QUESTION_TYPE.kunyomi) {
            // because Radicals, Onyomi and Kunyomi properties are arrays of strings
            for (const kanjiData of kanjis) {
                arr.push(...kanjiData.kunyomi);
            }
        }

        questionTypeChoices.push(property);
    }

    if (isMeaningTest) {
        scrubData(arrMeanings, QUESTION_TYPE.meaning);
    }

    if (isKanjiTest) {
        scrubData(arrKanji, QUESTION_TYPE.kanji);
    }

    if (isRadicalsTest) {
        scrubData(arrRadicals, QUESTION_TYPE.radicals);
    }

    if (isOnyomiTest) {
        scrubData(arrOnyomi, QUESTION_TYPE.onyomi);
    }

    if (isKunyomiTest) {
        scrubData(arrKunyomi, QUESTION_TYPE.kunyomi);
    }

    interface IQuestionChoices {
        questionType: QuestionType;
        correctAnswers: string[];
        answerChoices: string[];
    }

    function makeRadicalChoices(card: IConvertibleCard): string[] {
        const { bushu } = card;
        const radicalChoices = [...bushu];
        const otherBushuLookalikes: string[] = [];

        // add one bushu lookalike for each bushu
        bushu.forEach(b => {
            // Almost all regular kanji OR for kanji and radicals made of proto-radicals like 'one', 'two', 'mouth', 'slash', and 'stride'
            const { lookalikes } = kanjis.find(({ kanji }) => kanji === b) || card;
            const idx = Math.floor(Math.random() * lookalikes.length);
            const lookalike = lookalikes[idx];
            otherBushuLookalikes.push(...lookalikes); // store remaining bushu

            if (!radicalChoices.includes(lookalike) && lookalike !== card.kanji) {
                radicalChoices.push(lookalike);
            }
        });

        const otherRadicals = [...arrRadicals].reverse();
        let i = 0;

        // add more lookalike bushus to get the total numeber of choices up to 15
        while (radicalChoices.length < NUM_RADICAL_CHOICES) {
            const otherLookalike = otherBushuLookalikes[i];

            // adds radical choice from other lookalike group
            if (otherLookalike && !radicalChoices.includes(otherLookalike) && otherLookalike !== card.kanji) {
                radicalChoices.push(otherLookalike);
            }

            if (!otherLookalike) {
                let loop = true;
                while (loop && otherRadicals.length) {
                    const rad = otherRadicals.pop();

                    if (rad && !radicalChoices.includes(rad)) {
                        radicalChoices.push(rad);
                        loop = false;
                    }
                }
            }

            i++;
        }

        shuffle<string>(radicalChoices);

        return radicalChoices;
    }

    function kanjiLookalikes(card: IConvertibleCard): string[] {
        let otherLookalikes: string[] = [];
        let hasLookalikeInCommon = true;
        const setLevel = Math.floor(card.id / 100) * 100;
        const lowerBound = Math.max(0, setLevel - 100);
        const upperBound = Math.min(kanjis.length, setLevel + 100);

        // must check that new looklikes are ALL differnt from the correct answer kanji's
        while (hasLookalikeInCommon) {
            const otherKanji = kanjis.slice(lowerBound, upperBound)[Math.floor(Math.random() * (upperBound - lowerBound))];
            otherLookalikes = [otherKanji.kanji, ...otherKanji.lookalikes];
            hasLookalikeInCommon = false;

            if (otherKanji.type === KANJI_DATA_TYPES.pseudoRadical) {
                hasLookalikeInCommon = true;
            }

            otherLookalikes.forEach(lookalike => {
                if (card.lookalikes.includes(lookalike)) {
                    hasLookalikeInCommon = true;
                }
            });
        }

        return otherLookalikes;
    }

    function getQuestionChoices(card: IConvertibleCard): IQuestionChoices {
        const randomChoice = Math.floor(Math.random() * questionTypeChoices.length);
        const maxNumChoices = 6;
        const correctAnswers : string[] = [];
        const answerChoices: string[] = [];
        const questionType: QuestionType = isFlashcardSet ?
            (card.questionType as QuestionType) :
            questionTypeChoices[randomChoice];

        function makeKanjiChoices(): void {
            // adds correct answer(s)
            correctAnswers.push(card.kanji);
            answerChoices.push(card.kanji);
            // answerChoices.push(...card.lookalikes);
            const numLookAlikes = Math.floor(Math.random() * 3) + 2;

            while (answerChoices.length < numLookAlikes) {
                const wrongAnswer = card.lookalikes[Math.floor(Math.random() * card.lookalikes.length)];
                if (!correctAnswers.includes(wrongAnswer) && !answerChoices.includes(wrongAnswer) && wrongAnswer.trim()) {
                    answerChoices.push(wrongAnswer);
                }
            }

            const otherLookalikes = kanjiLookalikes(card);
            answerChoices.push(...otherLookalikes.slice(0, maxNumChoices - numLookAlikes));
            shuffle<string>(answerChoices);
        }

        function choicesWithSoundalikes(arrReadings: string[], questionType: QuestionType): void {
            let correctAnswer = '';

            // adds correct answer(s) if any
            if (questionType === QUESTION_TYPE.onyomi && card.onyomi.length) {
                correctAnswers.push(...card.onyomi.map(on => on.trim()));
                const o = Math.floor(Math.random() * card.onyomi.length); // generate an integer to randomly choose a correct answer
                correctAnswer = card.onyomi[o].trim();
                answerChoices.push(correctAnswer);
            } else if (questionType === QUESTION_TYPE.kunyomi && card.kunyomi.length) {
                correctAnswers.push(...card.kunyomi.map(kun => kun.trim()));
                const k = Math.floor(Math.random() * card.kunyomi.length); // generate an integer to randomly choose a correct answer
                correctAnswer = card.kunyomi[k].trim();
                answerChoices.push(correctAnswer);
            } else {
                correctAnswers.push(NO_READING);
            }

            // x does not exist in Japanese so this allows any first letter in the set of readings to be used
            // const answerFirstLetter = correctAnswer ? correctAnswer.slice(0, 1) : 'x';
            let answerFirstLetter = 'x';
            if (correctAnswer && correctAnswer.toLowerCase().slice(0, 2) === 'ch') {
                answerFirstLetter = correctAnswer.slice(0, 2);
            } else if (correctAnswer && correctAnswer.toLowerCase().slice(0, 2) === 'sh') {
                answerFirstLetter = correctAnswer.slice(0, 2);
            } else if (correctAnswer) {
                answerFirstLetter = correctAnswer.slice(0, 1);
            }

            /////////////////////////////////////////////////////////
            // ADDS ANSWER CHOICES WITH DIFFERENT FIRST LETTER //////
            /////////////////////////////////////////////////////////
            const numChoicesToAdd = Math.floor(Math.random() * 2) + 2 // randomly generate 2 or 3

            // finds ALL incorrect answer choices that DO NOT begin with the same letter
            const differentReadings: string[] = [];

            // if first leter of anser is 'sh' or 'ch'
            if (answerFirstLetter.length === 2) {
                for (let reading of arrReadings) {
                    reading = reading.trim();

                    if (reading.slice(0, 2) !== answerFirstLetter) {
                        differentReadings.push(reading.trim());
                    }
                }
            } else {
                for (let reading of arrReadings) {
                    reading = reading.trim();

                    if (reading.slice(0, 1) !== answerFirstLetter) {
                        differentReadings.push(reading.trim());
                    }
                }
            }

            const otherReadings: string[] = [];
            const differentReading1 = differentReadings[Math.floor(Math.random() * differentReadings.length)];
            const diffFirstLetter1 = differentReading1.slice(0, 2).toLowerCase() === 'ch' || differentReading1.slice(0, 2).toLowerCase() === 'sh' ?
                differentReading1.slice(0, 2) :
                differentReading1.slice(0, 1);

            // finds incorrect answer choices that begin with a specific different letter
            if (diffFirstLetter1.length === 2) {
                for (let reading of differentReadings) {
                    reading = reading.trim();

                    if (reading.slice(0, 2) === diffFirstLetter1 && !otherReadings.includes(reading)) {
                        otherReadings.push(reading.trim());
                    }
                }
            } else {
                for (let reading of differentReadings) {
                    reading = reading.trim();

                    if (reading.slice(0, 1) === diffFirstLetter1 && !otherReadings.includes(reading)) {
                        otherReadings.push(reading.trim());
                    }
                }
            }

            // adds incorrect answer choices that begin with that different letter
            const shuffled = [...otherReadings].sort(() => 0.5 - Math.random());
            answerChoices.push(...shuffled.slice(0, numChoicesToAdd));





            /////////////////////////////////////////////////////////////////////////////////////////
            // ADD ANSWER CHOICES THAT BEGIN WITH SAME LETTER OR ANOTHER LETTER IF NO READING ///////
            /////////////////////////////////////////////////////////////////////////////////////////

            // IF adds incorrect answer choices that begins with the same letter
            if (correctAnswer) {
                const numOtherChoicesToAdd = maxNumChoices - numChoicesToAdd - 2; // -2 because it has one correct answer and also NO_READING as answer
                const similarReadings: string[] = [];

                // create list of similar readings that start with the same letter
                if (answerFirstLetter.length === 2) {
                    for (let reading of arrReadings) {
                        reading = reading.trim();

                        if (reading.slice(0, 2) === answerFirstLetter && reading !== correctAnswer && !similarReadings.includes(reading)) {
                            similarReadings.push(reading);
                        }
                    }
                } else {
                    for (let reading of arrReadings) {
                        reading = reading.trim();

                        if (reading.slice(0, 1) === answerFirstLetter && reading !== correctAnswer && !similarReadings.includes(reading)) {
                            similarReadings.push(reading);
                        }
                    }
                }

                const shuffled = [...similarReadings].sort(() => 0.5 - Math.random());
                const verySimilarReadings: string[] = [];

                shuffled.forEach(reading => {
                    // if either reading or correct answer is a substring of the other
                    if (!verySimilarReadings.includes(reading) && (reading.includes(correctAnswer) || correctAnswer.includes(reading))) {
                        verySimilarReadings.push(reading.trim());
                    }
                });

                if (verySimilarReadings.length) {
                    const rando = Math.floor(Math.random() * verySimilarReadings.length)
                    answerChoices.push(verySimilarReadings[rando]);
                    // adds the remaininng is space is left
                    answerChoices.push(...shuffled.slice(0, numOtherChoicesToAdd - 1));
                } else {
                    answerChoices.push(...shuffled.slice(0, numOtherChoicesToAdd));
                }
            } else {
                // ELSE card has no correct answer, then will find a different first letter in order to add incorrect answer choices
                const numOtherChoicesToAdd = maxNumChoices - numChoicesToAdd - 1; // -1 because has no correct answer but also has NO_READING as an answer
                let diffFirstLetter2 = '';
                const otherReadings2: string[] = [];
                const shuffledDifferentReadings = [...differentReadings].sort(() => 0.5 - Math.random());

                // finds a second different first letter by looping through all different readings
                // until it finds enough wrong answer choices that begin with the same different first letter
                let c = 0;
                while (c < numOtherChoicesToAdd) {
                    c = 0;
                    for (const reading of shuffledDifferentReadings) {
                        const tempFirstLetter = reading.slice(0, 2).toLowerCase() === 'ch' || reading.slice(0, 2).toLowerCase() === 'sh' ?
                            reading.slice(0, 2) :
                            reading.slice(0, 1);

                        if (tempFirstLetter && tempFirstLetter !== answerFirstLetter && tempFirstLetter !== diffFirstLetter1) {
                            diffFirstLetter2 = tempFirstLetter;
                            break;
                        }
                    }

                    // finds incorrect answer choices that begin with a specific different letter
                    if (diffFirstLetter2 && diffFirstLetter2.length === 2) {
                        for (let reading of shuffledDifferentReadings) {
                            reading = reading.trim();

                            if (diffFirstLetter2 === reading.slice(0, 2) && !otherReadings2.includes(reading)) {
                                otherReadings2.push(reading);
                                c += 1;
                            }
                        }
                    } else if (diffFirstLetter2) {
                        for (let reading of shuffledDifferentReadings) {
                            reading = reading.trim();

                            if (diffFirstLetter2 === reading.slice(0, 1) && !otherReadings2.includes(reading)) {
                                otherReadings2.push(reading);
                                c += 1;
                            }
                        }
                    }
                }

                // adds incorrect answer choices that begins with another different letter
                const shuffled = [...otherReadings2].sort(() => 0.5 - Math.random());
                answerChoices.push(...shuffled.slice(0, numOtherChoicesToAdd));
            }





            /////////////////////////////////////////////////////////////////////////////////////////
            // Adds incorrect answer choices if not enough choices added yet.
            // This can happen is there are less choices in the sections above that begin with the desired letter.
            /////////////////////////////////////////////////////////////////////////////////////////
            while (answerChoices.length < maxNumChoices - 1) {
                const wrongAnswer = arrReadings[Math.floor(Math.random() * arrReadings.length)];
                if (!answerChoices.includes(wrongAnswer) && wrongAnswer.trim()) {
                    answerChoices.push(wrongAnswer);
                }
            }

            shuffle<string>(answerChoices);
            answerChoices.push(NO_READING);
        }

        switch (questionType) {
            case QUESTION_TYPE.meaning:
                // adds correct answer(s)
                correctAnswers.push(...card.meanings);
                const m = Math.floor(Math.random() * card.meanings.length);
                answerChoices.push(card.meanings[m]);
                answerChoices.push(...card.similarMeanings);

                // adds incorrect answer choices
                while (answerChoices.length < maxNumChoices) {
                    const wrongAnswer = arrMeanings[Math.floor(Math.random() * arrMeanings.length)];
                    if (!correctAnswers.includes(wrongAnswer) && !answerChoices.includes(wrongAnswer) && wrongAnswer.trim()) {
                        answerChoices.push(wrongAnswer);
                    }
                }

                shuffle<string>(answerChoices);
                break;

            case QUESTION_TYPE.kanji:
                makeKanjiChoices();
                break;

            case QUESTION_TYPE.radicals:
                correctAnswers.push(...card.bushu);
                const radicalChoices = makeRadicalChoices(card);
                answerChoices.push(...radicalChoices);
                break;

            case QUESTION_TYPE.onyomi:
                choicesWithSoundalikes(arrOnyomi, QUESTION_TYPE.onyomi);
                break;

            case QUESTION_TYPE.kunyomi:
                choicesWithSoundalikes(arrKunyomi, QUESTION_TYPE.kunyomi);
                break;

            default:
                break;
        }


        return {
            questionType,
            correctAnswers,
            answerChoices
        };
    }

    const testSet: ITestKanjiData[] = cards.map((card: IConvertibleCard) => {
        const { questionType, correctAnswers, answerChoices } = getQuestionChoices(card);
        return {
            ...card,
            meaning: card.meanings[0],
            questionType,
            correctAnswers,
            answerChoices,
        };
    });

    return testSet;
}

// SEARCH FUNCTIONS
function getSearchData(searchTerm: string): ISearchKanjiData[] {
    /*  DESCRIPTION
        Main search feature from search bar input. It finds the searchTerm kanji 
        and returns list of all derivatives broken down by derivation depth level.
        The string search term term can be one of many things: an Id number, 
        a kanji, a meaning, a radical, or a reading.
        The search function has six parts: 1A, 1B, 1C, 1D, 1E, and 2.

        PARAMETERS searchTerm: string

        RETURNS searchData: array
        DATA STRUCTURE of searchData and uniqueResults:
            Initialized as an object to prevent duplicates when new entries are
            added. Ultimately, returned as an array.

        key: integer : value: array
            [ 
                {
                   Id: number;
                    Kanji: string;
                    Meanings: string[];
                    Radicals: string[];
                    Onyomi: string[];
                    Kunyomi: string[];
                    Heading: string | number;
                },
                ...
            ]
        Example:
            { 
                43: [43, '由', ['reason','',''], ['bar','field','',''], 3], 
                57: [...], 
                ...
            }

        If there is data found, returned as an array
            [
                [Id #, Kanji, [...meanings], [...radicals], heading/depth], 
                [...],
                ...
            ]
        Example:
            [
                [43, '由', ['reason','',''], ['bar','field','',''], 3]
            ]

        If there is no data found, returned as boiler plate object
    */
    // if (isNaN(searchTerm)) {
    //     (searchTerm = searchTerm.trim().toLowerCase());
    // }

    /* Checks if search is with manually inputed radicals.
        Example: tree.sun.water splash
        if search term is punctuated kunyomi, this function 
        won't return result and getSearchData will continue
    */
    searchTerm = searchTerm.trim().toLowerCase();
    const delimiter: string = '.';
    if (searchTerm.includes(delimiter)) {
        const radicals = searchTerm.split(delimiter);

        if (radicals[radicals.length - 1] === '') {
            radicals.pop();
        }

        const [radicalSearchData, ] = getByRadicals(radicals);
        if (Object.values(radicalSearchData).length !== 0) {
            return Object.values(radicalSearchData);
        }
    };

    // interface ISearchData {
    //     [key: string]: IKanjiData;
    // }

    // 1 First Level Search
    let searchData: any = {};
    let uniqueResults: any = {};
    firstLevelSearch: {
        for (const kanji of kanjis) {
            if (kanji.type !== KANJI_DATA_TYPES.protoRadical) {

                // 1A Matches for Id
                if (parseFloat(searchTerm) === kanji.id) {
                    const tempResult: ISearchKanjiData = updateTempResult(kanji, 'Kanji #');
                    searchData[tempResult.id] = tempResult;
                    uniqueResults[tempResult.id] = tempResult;
                    break firstLevelSearch;
                }

                // 1B Matches for Kanji
                if (searchTerm === kanji.kanji.trim().toLowerCase()) {
                    const tempResult: ISearchKanjiData = updateTempResult(kanji, 0);
                    searchData[tempResult.id] = tempResult;
                    uniqueResults[tempResult.id] = tempResult;
                    break firstLevelSearch;
                }
                
                // 1C Matches for Meaning
                for (let i = 0; i < kanji.meanings.length; i++) {
                    if (searchTerm === kanji.meanings[i].trim().toLowerCase()) {
                        const tempResult: ISearchKanjiData = updateTempResult(kanji, 0);
                        searchData[tempResult.id] = tempResult;
                        uniqueResults[tempResult.id] = tempResult;
                        break firstLevelSearch;
                    }
                }

            } 

            if (kanji.type === KANJI_DATA_TYPES.protoRadical && searchTerm === kanji.meanings[0]) {
                for (const otherKanji of kanjis) {
                    if (otherKanji.radicals.includes(searchTerm)) {
                        const tempResult: ISearchKanjiData = updateTempResult(otherKanji, 0);
                        searchData[tempResult.id] = tempResult;
                        uniqueResults[tempResult.id] = tempResult;
                        break firstLevelSearch;
                    }
                }
            }
        }
    }


    // These results are not guaranteed to be unique. Therefore, it
    // needs a different for loop WITHOUT labeled break in if-statement
    const punctuated = addPeriodsTo(searchTerm);
    for (const kanji of kanjis) {
        if (kanji.type !== KANJI_DATA_TYPES.protoRadical) {
            // 1D Matches for Onyomi Readings
            for (let i = ONYOMI; i <= ONYOMI; i++) { // CHECK LATER
                for (let j = 0; j < kanji.onyomi.length; j++) {
                    const onyomi = kanji.onyomi[j];
                    if (searchTerm === onyomi.toString().trim().toLowerCase()) {
                        const tempResult: ISearchKanjiData = updateTempResult(kanji, ONYOMI_HEADING);
                        searchData[tempResult.id] = tempResult;
                    }
                }
            }

            // 1E Matches for Kunyomi Readings including punctuated ones such as "hito.tsu"
            const results = findKunyomisWithPeriod(punctuated, kanji, DATA_SOURCE.SEARCH);
            results.forEach(result => searchData[result.id] = result);
        }
    }

    // 2 Derivative Kanji Search
    const derivatives: ISearchKanjiData[] = findDerivativeKanji(uniqueResults);
    const searchDataValues: ISearchKanjiData[] = Object.values(searchData);

    return searchDataValues.length !== 0 ? [...searchDataValues, ...derivatives] : [];
}

function getSuggestions(searchTerm: string): ISearchKanjiData[] {
/*  DESCRIPTION
      Primary search function. getSuggestions takes a string search term as the user
      types into the search bar, displays suggestions returned from getSuggestions.
      These suggestions are clickable and they in turn call getSearchData.
      The suggestions function has two parts: 1 and 2.

    PARAMETERS searchTerm: string

    RETURNS suggestions: array

    DATA STRUCTURE of suggestions:
        [ 
                {
                    Id: number;
                    Kanji: string;
                    Meanings: string[];
                    Radicals: string[];
                    Onyomi: string[];
                    Kunyomi: string[];
                    Heading: string | number;
                    Match: string;
                    Priority: number,
                },
                ...
        ]
      Example:
        [ 
                {
                    Id: 43,
                    Kanji: '田',
                    Meanings: ['field'],
                    Radicals: ['box','ten'],
                    Onyomi: ['DEN'],
                    Kunyomi: ['ta', 'da'],
                    Heading: 'Meaning',
                    Match: 'field',
                    Piority: 1,
                },
                {
                    Id: 44,
                    Kanji: '由',
                    Meanings: ['reason'],
                    Radicals: ['field','bar'],
                    Onyomi: ['YUU','YU'],
                    Kunyomi: ['yoshi',],
                    Heading: 'Meaning',
                    Match: 'reason',
                    Piority: 2,
                },
                {
                    Id: 45,
                    Kanji: '甲',
                    Meanings: ['armor'],
                    Radicals: ['bar','field'],
                    Onyomi: ['KOU', 'KAN'],
                    Kunyomi: ['kai'],
                    Heading: 'Meaning',
                    Match: 'armor',
                    Piority: 2,
                },
                ...
            ]
*/
    // if (isNaN(searchTerm)) {
    //     searchTerm = searchTerm.trim().toLowerCase();
    // }
    searchTerm = searchTerm.trim().toLowerCase();

    let suggestions: ISearchKanjiData[] = [];
    /* These columns get displayed in Suggestions box.
       The number prefixes are the suggestions priorty; the lower the number, 
       the higher priority and thus listed on top of suggestions box. */

    // Match Type: Priority
    // Id: 0
    // Kanji: 0
    // Meaning: 1
    // Radical: 4
    // Onyomi: 2
    // Kunyomi: 3

    for (const kanji of kanjis) {
        if (kanji.type !== KANJI_DATA_TYPES.protoRadical) {

            // (1) Checks for Id a Kanji
            if (kanji.id.toString().includes(searchTerm)) {
                const tempResult: ISearchKanjiData = updateTempResult(kanji, 'Id');
                tempResult.match = kanji.id.toString(); // partially matched entry
                tempResult.priority = 0; // suggestion priority
                suggestions.push(tempResult);
            }

            // (2) Checks for Kanji match
            if (searchTerm === kanji.kanji.toLowerCase()) {
                const tempResult: ISearchKanjiData = updateTempResult(kanji, 'Kanji');
                tempResult.match = kanji.kanji; // partially matched entry
                tempResult.priority = 0; // suggestion priority
                suggestions.push(tempResult);
            }

            // Meanings
            for (let j = 0; j < kanji.meanings.length; j++) {
                const match = kanji.meanings[j];
                if (match.toLowerCase().includes(searchTerm)) {
                    const tempResult: ISearchKanjiData = updateTempResult(kanji, 'Meaning');
                    tempResult.match = match; // partially matched entry
                    tempResult.priority = 1; // suggestion priority
                    suggestions.push(tempResult);
                }
            }

            // Radicals
            for (let j = 0; j < kanji.radicals.length; j++) {
                const match = kanji.radicals[j];
                if (match.toLowerCase().includes(searchTerm)) {
                    const tempResult: ISearchKanjiData = updateTempResult(kanji, 'Radical');
                    tempResult.match = match; // partially matched entry
                    tempResult.priority = 4; // suggestion priority
                    suggestions.push(tempResult);
                }
            }

            // Onyomi
            for (let j = 0; j < kanji.onyomi.length; j++) {
                const match = kanji.onyomi[j];
                if (match.toLowerCase().includes(searchTerm)) {
                    const tempResult: ISearchKanjiData = updateTempResult(kanji, ONYOMI_HEADING);
                    tempResult.match = match; // partially matched entry
                    tempResult.priority = 2; // suggestion priority
                    suggestions.push(tempResult);
                }
            }

            // (3) Matches for Kunyomi readings including punctuated ones like "hito.tsu"
            const punctuated: string[] = addPeriodsTo(searchTerm);
            suggestions = [...suggestions, ...findKunyomisWithPeriod(punctuated, kanji, DATA_SOURCE.SUGGESTIONS)];

        }
    }

    // Sorts suggestions according to PRIORITY so that Id and Meanings appear as first suggestions
    // Using (x?.Priority || 1) because Priority is an optional property so TS throws error without fallback number
    suggestions.sort((a, b) => (a?.priority || 1) - (b?.priority || 1));

    // Will bump up exact search term matches to the top of the list
    const searchTermsWithPeriod = addPeriodsTo(searchTerm);
    const upperSuggestions: ISearchKanjiData[] = [];
    const lowerSuggestions: ISearchKanjiData[] = [];
    for (const suggestion of suggestions) {
        // let kanjiData = [suggestion.Id, suggestion.Kanji, ...suggestion.Meanings, ...suggestion.Onyomi, ...suggestion.Kunyomi];
        // kanjiData = kanjiData.map(item => typeof item === 'string' ? item.toLowerCase() : item);
        let termNotFound = true;
        for (const term of searchTermsWithPeriod) {
            if (suggestion.match === term) {
                // console.log('===== IF =====', term);
                // console.log({ kanjiData });
                termNotFound = false;
                upperSuggestions.push(suggestion);
            }
        }

        if (termNotFound) {
            lowerSuggestions.push(suggestion);
        }
    }

    return [...upperSuggestions, ...lowerSuggestions];
}

type RadicalData = [ISearchKanjiData[], string[]];

function getByRadicals(radicals: string[]): RadicalData {
    /*  DESCRIPTION
        Primary search function. getByRadicals takes an array of string radicals 
        as a search term and searches each one. It searches for all derivative 
        kanjis of search term and finds the intersections of all search results.

        PARAMETERS radicals: string[]

        DATA STRUCTURE of radicals
            ["radical name 1", "radical name 2", ...]

        RETURNS searchData: array

        DATA STRUCTURE of searchData:
        An array of 2 arrays of kanji data arrays
            [
                // searchData
                [
                    [Id #, Kanji, [...meanings], heading/depth, [...radicals]],
                    [...],
                    ...
                ],

                // parentsData
                [
                    [Id #, Kanji, [...meanings], heading/depth, [...radicals]],
                    [...],
                    ...
                ]
            ]
        Example:
            [
                // searchData
                [
                    [43, '由', ['reason','',''], 2, ['bar','field','','']],
                    [315, '曲', ['bend','',''], 2, ['reason', 'bar','','']]
                ],

                // parentsData
                [
                    [22, '十', ['ten','',''], 1, ['cross','','','']],
                    [23, '|', ['bar','',''], 1, ['vertical stick','','','']],
                    [43, '田', ['field','',''], 2, ['box', 'ten','','']]
                ],
            ]
    */
    let searchData: ISearchKanjiData[] = [];
    let c = 0;
    radicals.forEach((radical: string) => {
        let radicalData: ISearchResults = {};
        // Matches for Meaning and sets radical
        for (const kanji in kanjis) {
            for (let i = 0; i < kanjis[kanji].meanings.length; i++) {
                if (radical.trim().toLowerCase() === kanjis[kanji].meanings[i].trim().toLowerCase()) {
                    const tempResult: ISearchKanjiData = updateTempResult(kanjis[kanji], 0);
                    radicalData[tempResult.id] = tempResult;
                    break;
                }
            }
        };

        const radicalDerivatives: ISearchKanjiData[] = [...Object.values(radicalData), ...findDerivativeKanji(radicalData)];
        /* the c counter lets findIntersectionOf know to ignore the first empty
        searchData array and return radical derivatives as the first intersection.
        See comments in findIntersectionOf for explanation.
        */
        searchData = findIntersectionOf(searchData, radicalDerivatives, c);
        c++;
    });

    // // Sorts by depth of kanji derivation to display results in Id
    // searchData.sort();

    let parentsData: string[] = [];
    // searchData.length > 0 && (parentsData = findParentKanji(searchData));

    if (searchData.length) {
        parentsData = findParentKanji(radicals);
    }

     return [searchData, parentsData];
}

function getRadicalNames(radicalChars: string[]): string[] {
    /*  DESCRIPTION
        Primary search feature from Recent searches button. It finds the name of each radical fed to it.
    */
    const radicalNames: string[] = [];
    radicalChars.forEach(radical => {
        for (const kanji of kanjis) {
            if (kanji.type !== KANJI_DATA_TYPES.protoRadical) {
                // Matches for kanji and extracts radical name
                if (radical === kanji.kanji.trim()) {
                    const meanings = kanji.meanings;
                    radicalNames.push(meanings[0]);
                }
            }
        }
    });

    return radicalNames;
}

function getAllKanji(): string[] {
    return kanjis.slice(0, 2500).map(kanji => kanji.kanji);
}

function getKanjiData(kanjiId: number): IKanjiData {
    /*  DESCRIPTION
        Primary search function. getKanjiData takes a search term 
        and returns the flashcard data

        RETURNS kanjiData: object
        DATA STRUCTURE of kanjiData:
            {
                Id: integer, 
                Kanji: string (perhaps in unicode representation),
                Meanings: array of strings,
                Strokes: integer,
                Bushu: array of strings,
                Radicals: array of strings,
                Onyomi: array of strings,
                Kunyomi: array of strings,
                Mnemonic: string,
                Notes: string
            }
        Example:
            {
                Id:         100, 
                Kanji:      '下',
                Type:       'Kanji',
                Meanings:   ['down'],
                Strokes:    3,
                Bushu:      ['一', 'ト'],
                Radicals:   ['one', 'divination'],
                Onyomi:     ['KA','GE'],
                Kunyomi:    ['shita', 'sa.geru'],
                Mnemonic:   'On the down.m side...',
                Notes:      'Also has kunyomi of sa.garu'
            }
    */
    return kanjis.find(({ id }) => id === kanjiId) || {} as IKanjiData;
}

function getStandardKanjiSet(cardNumber: number): IKanjiData[] {
    /*  DESCRIPTION
        Primary search function. getStandardKanjiSet takes a card number and returns
        a set of 100 kanjiData arrays, each representing a flashcard.
    */

    const startingKanjiId = cardNumber - 1;
    return kanjis.slice(startingKanjiId, startingKanjiId + 100);
}

function getStandardTestSet(cardNumber: number, testChoices: ITestChoices): ITestKanjiData[] {
    /*  DESCRIPTION
        Primary search function. getStandardTestSet takes a card number and returns
        a set of 100 kanjiData arrays, each representing a flashcard with Test questions
    */

    const standardKanjiSet = getStandardKanjiSet(cardNumber);
    const testKanjiDataSet = convertToTestSet(standardKanjiSet as IConvertibleCard[], testChoices, false);
    shuffle<ITestKanjiData>(testKanjiDataSet);
    return testKanjiDataSet;
}

function getCustomKanjiSet(customKanjiSet: string): IKanjiData[] {
    const customSet : IKanjiData[] =[];
    customKanjiSet.split(',').forEach(char => {
        const foundKanji = kanjis.find(({ kanji }) => char === kanji);

        if (foundKanji) {
            customSet.push(foundKanji);
        }
    });

    return customSet;
}

function getCustomTestSet(customKanjiSet: string, testChoices: ITestChoices): ITestKanjiData[] {
    const testKanjiDataSet = getCustomKanjiSet(customKanjiSet);
    return convertToTestSet(testKanjiDataSet, testChoices, true);
}

function getFlashcardSet(): ICachedFlashcards {
    const cachedFlashcards = localStorage.getItem('flashcards');

    if (cachedFlashcards) {
        return JSON.parse(cachedFlashcards) as ICachedFlashcards;
    } else {
        const flashcards = flashcardEngine.makeFlashcards();
        localStorage.setItem('flashcards', JSON.stringify({
            flashcards,
            index: 0,
            originalNumOfCards: flashcards.length,
            alreadyChallenged: [],
            isCached: true,
        }));

        return {
            flashcards,
            index: 0,
            originalNumOfCards: flashcards.length,
            alreadyChallenged: [],
            isCached: false,
        };
    }
}

// function getFlashcardSet(): ITestKanjiData[] {
//     const testChoices: ITestChoices = {
//         isMeaningsTest: true,
//         isKanjiTest: true,
//         isOnyomiTest: true,
//         isKunyomiTest: true,
//     };
//     const standardKanjiSet = getStandardKanjiSet(400);

//      // TEMP 10 new cards
//     const newCardsSet: ITestKanjiData[] = standardKanjiSet.slice(90).map(kanjiData => ({
//         ...kanjiData,
//         CardType: `New ${kanjiData.Type}`,
//         Meaning: '',
//         questionType: '',
//         correctAnswers: [],
//         answerChoices: [],
//     }));

//     const testSet = convertToTestSet(standardKanjiSet.slice(0, 90), testChoices, false);
//      // TEMP 90 quiz cards
//     const quizSet: ITestKanjiData[] = testSet.map(testKanjiData => ({
//         ...testKanjiData,
//         CardType: `quiz ${testKanjiData.questionType}`,
//     }));

//     const flashcardSet = [...quizSet, ...newCardsSet];
//     shuffle<ITestKanjiData>(flashcardSet);

    
//     return flashcardSet;
// }

const searchServices = {
    convertToTestSet,
    kanjis,
    getSearchData,
    getSuggestions,
    getByRadicals,
    getRadicalNames,
    getAllKanji,
    getKanjiData,
    getStandardTestSet,
    getStandardKanjiSet,
    getCustomKanjiSet,
    getCustomTestSet,
    getFlashcardSet,
};

export default searchServices;